.fullwidth-background-image {
  .image-container {
    width: 100%;
    @media (min-width: $breakpoint-m) {
    height: 36em;
    }
  }
  @media (min-width: $breakpoint-m) {
    display: flex;
    margin-bottom: 6em;
    .image-container {
      width: 60%;
      height: auto;
    }
  }
  .background-image-holder {
    background: #cccccc no-repeat center center;
    background-size: contain;
    height: 100%;
  }
  @media (min-width: $breakpoint-m) {

    .background-image-holder {
      
      background-size: cover;
    }

  }

  @media (min-width: $breakpoint-m) {

    
    .mobile-only {
      display: none;
    }

  }


  @media (max-width: $breakpoint-m) {

    .desktop-only {
      display: none;
    }

  }


  .content {
    background: #f2f2f2;
    padding: 3em 0 3em 3%;
    @media (min-width: $breakpoint-m) {
      padding: 5em 0 5em 3%;
      width: 45%;
    }
    .view--news & {
      background-color: $cs-grey-lighter;
    }
  }
  .view__row--even &,
  &.fullwidth-background-image--paragraph:nth-child(even),
  .field__item.even & {
    flex-direction: row-reverse;
    .content {
      margin-left: 0;
      padding-right: 3%;
      @media (min-width: $breakpoint-m) {

      //text-align: right;
      ul {
        //text-align: right !important;
        //direction: rtl;
        //padding-right: 0;
      }
      }
      .container--half {
        margin-left: auto;
        margin-right: 0;
        padding: 0;
      }
    }
  }
  @media (max-width: $breakpoint-m-max) {
    .content .container--half {
      max-width: 72em;
      margin: 0 auto;
    }
  }
}

.fullwidth-background-image {
  h1 {
    text-align: left !important;
  }
  p {
    margin-bottom: 0 !important;
  }
}


.view__row--even .fullwidth-background-image .content {
  .field--node-news--body {
    h1, h2 {
      //text-align: right !important;
    }
  }
}

#block--8 {
  font-weight: bold;
  .right {
    color: black;
  }
}

#cmp--header {
 
  .admin-menu & {
    top: 29px;
  }
}

.region--navigation {
  display: inline-block;
}

.nav-bar {
  
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: $breakpoint-m) and (max-width: 1079px) {
    .block--main-menu {
      margin-top: 1em;
      text-align: center;
    }
    .menu__list--level1 {
      display: inline-block;
    }
    flex-wrap: wrap;
  }
  @media (min-width: 1080px) {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.region--navigation {
  width: 100%;
  vertical-align: middle;
}

.youtube--header {
  margin-top: 20px;
  width: 100vw;
  height: 56.25vw;
}

.demo--image {
  margin-top: 20vh;
  width: 100vw;
  height: 56.25vw;
}
.team-member--teaser {
  position: relative;
  margin-bottom: 1.25em;
  transition: background 0.4s;
  overflow: hidden;
  img {
    transition: opacity, 0.4s;
    width: 100%;
    backface-visibility: hidden;
  }
  h2 {
    font-weight: 600;
    text-transform: uppercase;
  }
  h2,
  h3 {
    color: #fff;
    transition: color, 0.4s;
    font-size: 1em;
    margin-bottom: 0.1em;
    font-family: $font-a-family;
  }
  .team-member--caption {
    padding: 20px 30px;
    background: #f4f4f4;
    transition: background 0.4s;
    color: #000;
    a {
      color: #000;
    }
  }
  .field-title-field {
    display: none;
  }
  &:after {
    background-color: #fff;
    opacity: 0.6;
    top: 0;
    bottom: 0;
    content: '';
    left: -100%;
    position: absolute;
    width: 200px;
    box-shadow: 0 0 100px #fff;
    transform: skew(-20deg);
    transition: all 0.4s ease;
  }
  .block-link:hover & {
    img {
      opacity: 0.7;
    }
    &,
    .team-member--caption {
      background: #f5f5f5;
    }
    h2,
    h3 {
      color: #000;
    }
    &:after {
      left: 200%;
    }
  }
}

.team-member--portrait {
  position: absolute;
  background: #000;
  color: #fff;
  top: 1em;
  line-height: 1em;
  z-index: 1;
  padding: 0.25em 0.75em;
  font-size: 0.9em;
  font-weight: 700;
  font-family: $font-a-family;
  &.team-member--portrait--hero {
    top: auto;
    font-size: 1.2em;
    bottom: 8rem;
    @media (max-width: $breakpoint-m-max) {
      top: auto;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%) translateY(50%);
      font-size: 1em;
    }
    @media (min-width: $breakpoint-l) {
      font-size: 1.5em;
      bottom: 11rem;
    }
  }
}

.team--member__title {
  margin-bottom: 3em;
  h2 {
    margin-bottom: 0.1em;
    @media (min-width: $breakpoint-m) {
      font-size: 3em;
    }
  }
  h3 {
    font-style: italic;
    font-size: 1.6em;
  }
}

.team-member--portrait--number {
  background: $cs-d;
  border-radius: 50%;
  padding: 0.5em;
  font-size: 2em;
  display: inline-block;
  margin-bottom: 1em;
  height: 2.5em;
  width: 2.5em;
  text-align: center;
}

@media (min-width: $breakpoint-m) {
  .field-fullwidth-background-text .team-member--portrait--number--wrapper {
    text-align: inherit;
  }
}

.page-team #cmp--content {
  @extend .container;
}

.field-team-member-academic-title {
  display: inline-block;
  float: left;
  font-family: "Big Noodle Titling", sans-serif;
  font-size: 28px;
}

.field-team-member-last-name {
  font-family: "Big Noodle Titling", sans-serif;
  font-size: 28px;
}

.field-team-member-position {
  font-weight: bold;
  font-size: 17px;
  color: #f58220;
}

.field-team-member-email {
  &::before {
    content: "\e75a";
    font-family: "themify";
    font-size: 0.9em;
    margin-right: 10px;
  }
}

.field-team-member-phone {
  margin-top: 20px;
  &::before {
    content: "\f095";
    font-family: "FontAwesome";
    font-size: 1em;
    margin-right: 10px;
  }
}

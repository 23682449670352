.view--products--page-all {
  img {
    width: 100%;
  }

  .isotope--item {
    margin-bottom: 1.25rem;
  }
.isotope .grid__inner {
    background: #f9f9f9;
}
  .view__field--title,
  .view__field-view-node {
  
    padding: 0.5rem 1rem;
  }

  .view__field--title {
    padding-bottom: 0;
  }
}
.node-type--synex-product {
  #title--main {
    display: none;
  }
}
/*------------------------------------*\
  TYPOGRAPHY
\*------------------------------------*/


/**
 * CONTENTS
 * HEADLINES...........
 * ANCHORS.............
 * WHITESPACE..........
 */


body {
  font-weight: $font-b-weight-light;
}

/*------------------------------------*\
  $HEADLINES
\*------------------------------------*/


/**
 * Reset margin on headlines
 */

#title--main {
  text-align: center;
}

h1,
h2,
h3,
h4 {
  margin-top: 0;
  margin-bottom: $spacing-xs;
  color: #000;
  font-weight: $font-a-weight-normal;
  font-family: $font-a-family;
  line-height: 1;
  letter-spacing: 0.5px;
}

h1 {
  font-size: $font-size-h1*0.75;
  @media (min-width: $breakpoint-m) {
    font-size: $font-size-h1;
  }
}

h2 {
  font-size: $font-size-h2;
}

h3 {
  font-size: $font-size-h3;
  color: $cs-b;
  margin-bottom: .5rem;
}

h4 {
  font-size: $font-size-h4;
}

h5 {
  margin: 0;
  font-size: $font-size-h5;
}

h6 {
  margin: 0;
  font-size: $font-size-h6;
}


/*------------------------------------*\
  $ANCHORS
\*------------------------------------*/


/**
 * <span class="a">readmore...</span>
 */

a,
.a {
  color: $a-color;
  text-decoration: $a-text-decoration;
  outline: none !important;
  &:visited {
    color: $a-color-visited;
  }
  &:hover,
  &:focus {
    color: $a-color-hover;
    text-decoration: $a-text-decoration-hover;
  }
}


/**
 * Reversed link behaviour
 */

.a--reversed {
  text-decoration: $a-text-decoration-hover;
  &:hover,
  &:focus {
    text-decoration: $a-text-decoration;
  }
}

body {
  font-family: $font-b-family;
}


ul {
  text-align: left;
}



.septem-menu {
  z-index: 101;
}

.front .cmp--content {
  h1 {
    text-align: center;
    margin: 0 auto;
    max-width: 13em;
    margin-bottom: 0.75em;
  }
  // .tabs--primary {
  //   display: none;
  // }
}



.cmp--content {
  margin-top: 2em;
}

.form__item--field-form-contact-datenschutz-und {
	display: flex !important;
}

.form__item--field-form-contact-datenschutz-und > label {
	display: none !important;
}

.form__item--field-form-contact-datenschutz-und > small {
	margin-left: 10px !important;
  font-size: 16px;
}

.form__item--field-form-contact-datenschutz-und > input {
	margin-top: 3px !important;
}

.front .cmp--content {
  h1.field--node-page--title-field {
    display: none !important;
  }
}

.field-folder {
  display: none;
}

@media only screen and (min-width: 600px) {
  .text-left-box, .text-right-box {
    width: 50%;
    float: left;
  }
}

/*
main#content--main {
  box-shadow: inset 0px 0px 30px rgba(0,0,0,0.2);
  padding-top: 30px;
}

body.front {
  main#content--main {
    box-shadow: none;
    padding-top: 0;
  }
}
*/

body {
  -webkit-font-smoothing: antialiased;
}

#block--contact {
  padding: 0;
}

#cmp--content {
  box-shadow: inset 0 0 20px rgba(0,0,0,0.3);
  padding-top: 5em;
  padding-bottom: 4em;
}

body.page-contact {
  #cmp--content {
    margin-top: 0;
    padding-bottom: 0;
  }
}

body.front {
  #cmp--content {
    margin-top: -30px;
    padding-bottom: 0;
  }
}

.view--news--page-all__header {
  margin-bottom: 100px;
}

h1#title--main {
  margin-bottom: 2em;
}

h1.field-title-field {
  margin-bottom: 2em;
}
.url-textfield {
  visibility: hidden;
  height: 10px;
}

.organisation-name {
	font-size: 2em;
}

.paragraphs-items {

   h2 {
    font-family: $font-a-family;
    font-weight: $font-a-weight-normal;
    text-transform: uppercase;
  }
}
.entity-paragraphs-item {
  margin-top: 2rem;
}
.paragraphs-item-intro {
  font-size: 1.5em;
}

.field--paragraphs_item-header_image--field-paragraph-image,
.paragraphs-item-intro,
.field-paragraph-textarea,
.paragraphs-item-50-50 .field--type-text-long,
.paragraphs-item-33-33-33 .field--type-text-long,
.paragraphs-item-gallery,
.paragraphs-item-image-left-text-float,
.paragraphs-item-image-with-caption,
.paragraphs-item-download {
  margin-bottom: 4rem;
}

.paragraphs-item-50-50 img,
.paragraphs-item-33-33-33 img {
  margin-bottom: 1.5rem;
}

.paragraphs-item-image-left-text-float {
  .group-image {
    float: left;
    max-width: 40%;
    margin-right: $spacing-s;
    margin-bottom: $spacing-xs;
  }
  @media (max-width: $breakpoint-m-max) {
    .group-image {
      max-width: 100%;
      float: none;
      text-align: center;
      img {
        width: 100%;
        max-width: 550px;
        display: inline-block;
      }
    }
  }
  .field-image-caption {
    font-size: .9em;
    font-style: italic;
  }
}

.field-paragraph-gallery {
  img {
    width: 100%;
    transition: all 0.2s;
    &:hover {
      opacity: 0.75;
    }
  }
}

.a-buttons {
  a {
    font-family: $font-a-family;
    font-weight: 300;
    text-transform: uppercase;
    display: inline-block;
    margin: 0 1em 0 0;
    padding: 0.3em 0.7em 0.4em;
    white-space: nowrap;
    cursor: pointer;
    transition: all, 0.3s;
    background: transparent;
    border: 3px solid rgba($cs-b, 1);
    color: $cs-b;
    text-decoration: none;
    font-size: 1.2em;
    &:hover,
    &:focus {
      background: $cs-b;
      color: #fff;
      border-color: transparent;
    }
  }
}

/* Browser Resets
*********************************/

.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
  outline: none;
}

.slides,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}


/* FlexSlider Necessary Styles
*********************************/

.flexslider {
  margin: 0;
  padding: 0;
  .not-front & {
  max-height: 540px;
  .slides img {
  max-height: 540px;
  float: right;
  width: auto;
}
  }
}

@media (min-width: $breakpoint-m) {
.view__field--field-slide-image-1__content {
  max-height: 40em;
  overflow: hidden;
}
.flexslider {
  .not-front & {
  max-height: 40em;
  .slides img {
height: auto;
}
  }
}
.flexslider .slides>li {
  height: 40em;
}
}

.flexslider .slides>li {
  display: none;
  overflow: hidden;
  -webkit-backface-visibility: hidden;

  /* &:after {
  display: block;
  height: auto;
  content: url(../assets/bend.svg);
  position: absolute;
  bottom: -44%;
  right: -30%;
  left: -60%;
}*/
}


/* Hide the slides before the JS is loaded. Avoids image jumping */

.flexslider .slides img {
  display: block;
  width: 100%;
  height: auto;
}

.flex-pauseplay span {
  text-transform: capitalize;
}


/* Clearfix for the .slides element */


.slides:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '\0020';
  line-height: 0;
}

html[xmlns] .slides {
  display: block;
}

* html .slides {
  height: 1%;
}


/* No JavaScript Fallback */


/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */

.no-js .slides>li:first-child {
  display: block;
}

.flexslider {
  position: relative;
  overflow: hidden;
  .view__row {
    position: relative;
  }
  .slides>li {
    position: relative;
  }
  &:hover {
    .flex-prev,
    .flex-next {
      opacity: 1;
    }
  }
}

.flex-control-paging a {
  cursor: pointer;
}


/**
 * Controls
 */

.flex-prev,
.flex-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.2s;
  margin: 0 0.5em;
  span,
  .fa {
    font-size: 3em;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.35);
  }
  &,
  &:hover,
  &:visited,
  &:focus {
    color: #fff;
    text-decoration: none;
  }
}

.flex-next {
  right: 0;
}

.flex-disabled {
  display: none;
}

.flex-control-nav {
  width: 100%;
  position: absolute;
  height: auto;
  padding: 2px 0;
  text-align: center;
  li,
  a {
    display: inline-block;
  }
  a {
    margin: 0 0.2em;
    width: 0.8em;
    height: 0.8em;
    background: $cs-b;
    border-radius: 100%;
    text-decoration: none;
    text-indent: -999em;
    cursor: pointer;
    line-height: 71%;
  }
  .flex-active {
    background: $cs-a;
  }
}

.flex-caption {
  .cmp--hero-text {
    margin-top: 1em;
    color: #000;
  }
  .slide-bright-font,
  .hero-slide--bright-text {
    &,
    h1,
    h2,
    h3,
    h4 {
      color: #fff;
    }
    .slide-bright-font {
      &,
      h1,
      h2,
      h3,
      h4 {
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.35);
      }
    }
    a {
      text-shadow: none;
    }
    @media (min-width: $breakpoint-m) {
      &.slide-halfwidth {
        &,
        h2,
        h3,
        h4 {
          color: $font-base-color;
          text-shadow: none;
        }
      }
    }
  }
  h1,
  h2,
  h3,
  h4 {
    font-family: $font-a-family;
    font-weight: $font-a-weight-normal;
    margin: 0;
    line-height: 1;
  }
  h3 {
    margin-top: -0.7rem;
  }
  a {
   @extend .button;
  }
  .slide-halfwidth {
    position: absolute;
    z-index: 1;
    width: 50%;
    top: 2em;
    padding-left: 3em;
    .inner {
      max-width: 20em;
    }
    @media (max-width: $breakpoint-m-max) {
      width: 100%;
    }
    @media (min-width: $breakpoint-s) {
      top: 6em;
      padding-left: 6em;
    }
    blockquote {
      border: 0;
      padding: 0;
      h4,
      h5 {
        margin-bottom: 0;
        font-size: 1em;
      }
      em {
        margin-top: 1em;
        font-size: 1rem;
        @media (min-width: $breakpoint-s) {
          font-size: 1.2rem;
        }
        @media (min-width: 1060px) {
          font-size: 1.4rem;
        }
      }
    }
  }
  .slide-fullwidth,
  .slide-fullwidth-narrow {
    position: absolute;
    width: 100%;
    padding: 0 2em;
    top: 0;
    bottom: 0;
    z-index: 200;

    &>.container {
      display: flex;
      align-items: center;
      height: 100%;
      &>.inner {

      }
    }

    h1,
    h2,
    h3 {
      margin-bottom: 0.1em;
    }
    h1,
    h2 {
      font-size: 1rem;
      letter-spacing: 0.05rem;
    }
    h3 {
      font-size: 1rem;
    }
    @media (min-width: $breakpoint-s) {
      h1,
      h2 {
        font-size: 1.3rem;
      }
      h3 {
        font-size: 1rem;
      }
    }
    @media (min-width: $breakpoint-m) {
      h1,
      h2 {
        font-size: 2rem;
      }
      h3 {
        font-size: 1.5rem;
      }
    }
    @media (min-width: $breakpoint-l) {
      h1,
      h2 {
        font-size: 7.5rem;

      }
      h3 {
        font-size: 4.5rem;
      }
    }
  }
}


#flexslider-1 {
  opacity: 0;
  transition: opacity, 0.3s;

  &.is-ready {
    opacity: 1;
  }
}

#block--language {
  ul {
    li {
      display: inline-block;
      padding: 0 5px;
      &:first-child {
        border-right: 1px solid #666;
      }
    }
  }
}

.page-contact .cmp--content {
  margin-top: 2em;
}

.field-location-geofield {
  margin-bottom: 4em;
}

.view--locations--page-all {
  margin-bottom: 3rem;

  .view__row {
    margin-bottom: 1.25rem;
  }
}

.gmap-popup {
  padding: 1em;
  .organisation-name {
    font-size: 1.1em;
    font-weight: 400;
    text-transform: uppercase;
  }
  .field-location-phone {
    padding-left: 2em;
    position: relative;
    margin: 1em 0 0 0;
    &:before {
      position: absolute;
      left: 0;
      width: 13px;
      height: 18px;
      content: url('/sites/all/themes/roromedia/assets/phone.png');
      display: inline-block;
      bottom: 3px;
      left: 2px;
    }
  }
  .field-location-email {
    padding-left: 2em;
    position: relative;
    &:before {
      position: absolute;
      left: 0;
      width: 18px;
      height: 14px;
      content: url('/sites/all/themes/roromedia/assets/email.png');
      display: inline-block;
    }
  }
}

.field-free-body-field {
  margin-top: 60px;
}

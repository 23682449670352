.button {
  font-family: $font-a-family;
  font-size: 1.5rem;
  padding: 0.1em 0.7em 0.2em;
  margin-top: 1em;
  background: transparent;
  border: 3px solid rgba($cs-b, 1);
  color: $cs-b !important;
  &:hover,
  &:focus {
    background: $cs-b;
    color: #fff !important; 
    border-color: transparent;
  }
}

.button--alt {
  font-family: $font-a-family;
  font-weight: 300;
  text-transform: uppercase;
  background: $cs-d;
  border: 1px solid $cs-d;
  transition: all, 0.3s;
  &,
  &:visited {
    color: #000;
  }
  &:hover,
  &:focus {
    text-decoration: none;
    background: #fff;
    color: #000;
    border-color: #000;
  }
}

.button--outline {
  font-weight: 300;
  text-transform: uppercase;
  background: transparent;
  border: 3px solid rgba(#fff, 1);
  color: #fff;
  transition: all, 0.3s;
  &:hover,
  &:focus {
    text-decoration: none;
    background: #fff;
    color: #000;
  }
}

.button--outline--dark {
  background: transparent;
  border: 3px solid rgba(#000, 1);
  color: #000;
  &:hover,
  &:focus {
    background: $cs-b;
    color: #fff;
    border-color: transparent;
  }
}

.view--customers {
  margin-top: 6rem; 
  padding-top: 6rem;
  border-top: 1px solid $cs-grey-lighter;
  .view__header {
  	text-align: center;
  }
  .view__row {
    height: 120px;
    img {
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
      @media (min-width: $breakpoint-l) {
        padding: 1em; 
      }
    }
  }
}  
.request-callback {
  background-color: $cs-grey-dark;
  text-align: center;
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
  	margin-right: 2rem;
    @extend h3;
    color: #fff;
  }
  .button {
  	margin-top: 0;
  }
}

@media (min-width: 1000px) {
  .block--teasers-teaser-front {
    background: url('/sites/all/themes/roromedia/assets/icon_bg.svg') no-repeat 0 0;
    background-size: 50%;
    background-position-x: 135%;
    padding-bottom: 6rem;
    margin-bottom: 0 !important;
  }
}

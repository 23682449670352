.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe,
  object,
  video,
  embed {
   position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;   
  }
}

.embed-container iframe,
.embed-container object,
.embed-container embed {

}



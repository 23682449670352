.region--footer {
  border-top: 0.1em solid #e9e9e9;
  padding-top: 2em;
  padding-bottom: 3em
}

.block--3 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  >a,
  >div {
    width: 100%;
    text-align: center;
    margin-bottom: 1em;
  }
  @media (min-width: $breakpoint-l) {
    flex-wrap: nowrap;
    justify-content: space-between;
    > a {
      width: 9.5em;
      margin-bottom: 0;
    }
    > div {
      width: auto;
      margin-bottom: 0;
    }
  }
  a {
    color: #6a6a6a;
    transition: color, 0.1s;
  }
  h4,
  h5 {
    margin-bottom: 0;
    font-family: $font-a-family;
    letter-spacing: 0.15em;
    color: #6a6a6a;
    font-size: 1.2em;
    font-weight: 400;
    line-height: 1.1em;
  }
  h4 {
    font-weight: 700;
  }
}

.footer-facebook-link {
  font-size: 1.2em;
  span {
    background: #e5e5e5;
    transition: background, 0.2s;
    border-radius: 50%;
    height: 2.5em;
    width: 2.5em;
    display: inline-block;
    line-height: 2.5em;
    @media (min-width: $breakpoint-l) {
      float: right;
    }
  }
  &:hover,
  &:focus {
    text-decoration: none;
    color: #fff;
    span {
      background: #3b5998;
    }
  }
}

.view--customers--block__content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.view--customers .view__row {
  margin: 20px 0;
}

.view--customers .view__row img {
  padding: 0em !important;
}

.view--customers--block__content {
  .view__row {
    img {
      //width: 100% !important;
    }
  }
}

#block--customers-block {
  margin-bottom: 80px;
}

@if $cmp-menu {

/*------------------------------------*\
  MENU
\*------------------------------------*/
/**
 * CONTENTS
 * DEFAULT.............
 * OFF CANVAS..........
 * HORIZONTAL..........
 * VERTICAL............
 */



/*------------------------------------*\
  $DEFAULT
\*------------------------------------*/
/**
 * 1. Set position root for off canvas menu
 * 2. Set position root for sub menus (dropdowns)
 */
html {
  position: relative; /* [1] */
}

.menu__list {
  @extend .u-list-reset;
  margin-bottom: 0;
}

.menu__list--level1 {
  transition: transform 0.2s;
}

.menu__item {
  position: relative; /* [2] */
}

.menu__link {
  display: block;
  text-decoration: none;
}

.menu__checkbox {
  display: none;
}

.menu__controls {
  @media (min-width: $cmp-menu-mobile-breakpoint) {
    display: none;
  }
}



/*------------------------------------*\
  $OFF CANVAS
\*------------------------------------*/

.menu--off-canvas {
  .menu__controls {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
  }
  .menu__list--level1 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    padding-top: $spacing-xl;
    max-width: 20em;
    width: 70%;
    transform: translate(-100%, 0);
  }
  .menu__checkbox:checked + .menu__list--level1 {
    transform: translate(0, 0);
  }
}



/*------------------------------------*\
  $HORIZONTAL
\*------------------------------------*/

.menu--horizontal {
  .menu__list--level1 {
    @extend .u-cf;
  }
  @media (max-width: ($cmp-menu-mobile-breakpoint - 1)) {
    .menu__list--level1 {
      height: 0;
      transform: scale(1, 0);
      transform-origin: 0 0;
    }
    .menu__checkbox:checked + .menu__list--level1 {
      height: auto;
      transform: scale(1, 1);
      // z-index: 1000;
      // position: relative;
    }
  }
  @media (min-width: $cmp-menu-mobile-breakpoint) {
    .menu__list--level1 .menu__list {
      position: absolute;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.2s;
    }
    .menu__list--level1 .menu__list .menu__list {
      top: 0;
      left: 100%;
    }
    .menu__item:hover > .menu__list,
    .menu__item:focus > .menu__list {
      visibility: visible;
      opacity: 1;
    }

    .menu__item--level1 {
      float: left;
    }
  }
}



/*------------------------------------*\
  $VERTICAL
\*------------------------------------*/

.menu--vertical {
  // no styling necessary
  // use this class to add your own stylings
  // (but not here)
}

} // endif
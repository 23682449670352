.nolink {
  cursor: pointer
}



@media (min-width: $cmp-menu-mobile-breakpoint) {
  .menu--horizontal {
    .menu__link {
      font-weight: 400;
      font-family: $font-b-family;
    }
    .menu__link--level1 {
      text-transform: uppercase;
      padding: 0.25em 0.75em;
      transition: color, 0.3s;
      font-size: 1.2rem;
      color: black;
      @media (max-width: 875px) {
        padding: 0.25em 0.5em;
        font-size: 0.85em;
      }
      &:hover,
      &:focus {
        text-decoration: none;
        color: $cs-grey-dark;
      }
      &.is-active {}
    }
    .menu__list--level2,
    .menu__list--level3 {
      z-index: 1003;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }
    .menu__list--level2 {
      top: 100%;
      transition: visibility 0s, opacity 0.3s;
      font-size: 0.9em;
      left: 50%;
      transform: translateX(-50%);
      min-width: 17.5em;
      margin-top: 1em;
      // &:before {
      //   content: '';
      //   width: 0;
      //   height: 0;
      //   border-left: 1em solid transparent;
      //   border-right: 1em solid transparent;
      //   border-bottom: 0.8em solid #fff;
      //   position: absolute;
      //   bottom: 100%;
      //   left: 50%;
      //   margin-left: -1em;
      // }
      &:after {
        content: '';
        width: 100%;
        height: 1em;
        position: absolute;
        bottom: 100%;
        left: 0;
        z-index: 1004;
      }
      .menu__link {
        color: #000;
        background: #fff;
        padding: 1em 0.75em;
        text-transform: uppercase;
        display: block;
        border-bottom: 1px solid #efefef;
        transition: background, 0.3s;
        &:hover,
        &:focus {
          color: $cs-a;
          background: $cs-d;
          text-decoration: none;
        }
        &.is-active {
          font-weight: $font-a-weight-bold;
        }
      }
      .menu-item:last-child .menu-link {
        border-bottom: none;
      }
    }
    .menu__list--level3 {
      min-width: 14em;
      margin-left: -1px;
    }
  }
  .region--sidebar1 {
    .block--menu-block {
      h2 {
        font-size: $font-size-xl;
        border-bottom: 1px solid $cs-grey;
        padding-bottom: 0.4em;
        margin-bottom: 0.8em;
      }
      .menu__list {
        .menu__item--level2 {
          position: relative;
          margin-top: 0.8em;
          .menu__list--level3 {
            display: none;
          }
          &.is-open {
             .menu__list--level3 {display: block;}
            .accordion-menu {
              &:after {
                content: "-" !important;
              }
            }
          }
          &.is-active {
            .menu__list--level3 {
              display: block;
            }
          }
          .menu__link--level2 {
            font-size: $font-size-l;
            padding-bottom: 0.4em;
            margin-right: 1em;
            display: inline-block;
          }
          .accordion-menu {
            width: $font-size-xl;
            height: $font-size-xl;
            position: absolute;
            right: 0;
            top: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid $cs-b;
            border-radius: 50%;
            &:after {
              content: "+";
              color: $cs-b;
            }
          }

        }
        .menu__item--level3 {
          padding: 0.4em 0 0.4em 2em;
          border-top: 1px solid $cs-grey-light;
          &:last-child {
            border-bottom: 1px solid $cs-grey-light;
          }
          a {
            color: $cs-grey;
          }
        }
        .menu__link--level2 {
          font-weight: $font-b-weight-bold;
        }
      }
    }
  }
}

@media (max-width: ($cmp-menu-mobile-breakpoint - 1)) {
  .menu__controls {
    position: absolute;
    top: 0;
    right: 10px;
  }
  .nav-bar {
    justify-content: flex-start;
    align-items: flex-start;
  }
  .menu--horizontal {
    .menu__item {
      background-color: #000;
      .menu__link {
        padding: 1em;
        color: #fff;
        border-bottom: 1px solid #444;
        transition: all, 0.3s;
        font-family: $font-a-family;
        &:focus,
        &:hover {
          text-decoration: none;
          background: #333;
        }
      }
      .menu__link--level1 {
        text-transform: uppercase;
        font-weight: 600;
      }
      .menu__link--level2 {
        text-transform: uppercase;
        font-size: 0.9em;
        padding-left: 2.5rem;
      }
      .menu__link--level3 {
        padding-left: 4.5rem;
        font-size: 0.85em;
      }
    }
    .menu__list--level1 {
      position: absolute;
      margin-top: 76px;
      left: -1em;
      right: -1em;
      z-index: 1000;
      // width: 100%;
    }
  }
  .menu__controls {
    text-align: right;
    .menu__toggle {

    }
  }
}

.burger-menu {
  .cross,
  .hamburger {
    line-height: 1em;
    position: absolute;
    right: 1rem;
    font-size: 1.5em;
    color: #999;
    z-index: 120;
    cursor: pointer;
    top: 69px;
  }
  .cross {
    display: none;
  }
  .secondary-navigation {
    overflow-y: scroll;
    visibility: hidden;
    right: -15em;
    position: fixed;
    top: 0;
    height: 100vh;
    z-index: 100;
    background: $cs-grey-lighter;
    width: 15em;
    padding: 1em 0;
    transition: all 0.3s;
    &.is-active {
      visibility: visible;
      right: 0;
    }
    h2 {
      padding: 0 1.5rem;
    }
    .menu__link {
      padding: 0.75rem 1.5rem;
      border-bottom: 1px solid #dedede;
    }

    .menu__link--level2 {
      padding-left: 3em;
      font-size: 0.9em;
      .menu__link-level3 {
        padding-left: 4.5em;
        padding-left: 1.5em;
      }
    }
  }
}

.logo__img {
  min-width: 160px;
}

.nav-bar {
  //padding-left: 15px;
}


@media (max-width: $cmp-menu-mobile-breakpoint)  {
  .menu-name-main-menu {
     display: none;
  }

}

#block--menu-secondary-menu {
  position: relative;
  padding: 6px;
  float: right;
  text-align: right;

  ul.menu__list--level1 {
    display: inline-block;
    li {
      float: left;
      padding: 0 10px;
      a {
        color: #666;
      }
    }
  }
}

#cmp--offcanvas {
  background: #f3f3f3;
}

#block--language {
  float: left;
  padding: 6px;
  ul.language-switcher-locale-url {
    margin: 0;
    padding: 0;
    li {
      a {
        color: #666;
      }
      &.active {
        a {
          color: #f58220;
        }
      }
    }
  }
}


#block--main-menu {
  margin-top: 25px;
}

#logo--main {
  margin-top: 25px;
}

a.menu__link--1225 {
  padding-right: 0 !important;
}

/*------------------------------------*\
  UTILITY
\*------------------------------------*/
.element-invisible,
.hide {
  @extend .u-visuallyhidden;
}

.clearfix {
  @extend .u-cf;
}

.intro {
  font-size: $font-size-l;
  p {
    margin-bottom: 1.5rem;
  }
}

.font-a-family {
  &,
  a {
    font-family: $font-a-family;
  }
}

.font-b-family {
  &,
  a {
    font-family: $font-b-family;
  }
}

.letter-spacing {
  letter-spacing: 0.15em;
}

.top-margin {
  margin-top: 6rem;
}
.bottom-margin {
  margin-bottom: 6rem;
}

/*
 * Text
 */

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-uppercase {
  text-transform: uppercase;
}


/*
 * Positions
 */

.fixed {
  position: fixed;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.static {
  position: static;
}


/*
 * Float
 */

.left {
  float: left;
}

.right {
  float: right;
}


/*
 * Anchor
 */

.block-link {
  text-decoration: none;
  color: $font-base-color;
  display: block;
  &:hover,
  &:focus,
  &:visited {
    text-decoration: none;
    color: $font-base-color;
  }
}


/*
 * Responsive visibily
 */

.hide-s {
  @media (max-width: $breakpoint-s-max) {
    display: none;
    visibility: hidden;
  }
}

.show-s {
  display: none;
  visibility: hidden;
  @media (max-width: $breakpoint-s-max) {
    display: block;
    visibility: visible;
  }
}

.hide-m {
  @media (max-width: $breakpoint-m-max) {
    display: none;
    visibility: hidden;
  }
}

.show-m {
  display: none;
  visibility: hidden;
  @media (max-width: $breakpoint-m-max) {
    display: block;
    visibility: visible;
  }
}

.hide-js {
  .js & {
    display: none;
    visibility: hidden;
  }
}

// flex
.vertical-align-center {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

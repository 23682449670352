.field-download {
  .field__item {
    display: flex;
    justify-content: space-between;
    font-family: $font-a-family;
    align-items: center;
    padding: 0.75em 0.5em 1em 0.5em;
    border-bottom: 1px solid #e6e6e6;
    background: #f2f2f2;
    [class^="ti-"] {
      font-size: 1.3em;
      margin-right: 0.5em;
      position: relative;
      top: 2px;
    }
    &:last-child {
      border-bottom: none;
    }

    a {
      transition: opacity, 0.3s;
      font-size: 1.5em;
    }

    a:hover,
    a:focus {
      text-decoration: none;
      opacity: 0.7;
    }
  }
}

span.filesize {
  font-size: 1.5em;
}

/*------------------------------------*  $VARIABLES
\*------------------------------------*/
/** CONTENTS COLOR-SHEME.........Set theme colors TYPOGRAPHY.......... BREAKPOINTS......... SPACING............. LAYOUT.............. COMPONENTS.......... SCAFFOLDING......... */
/*------------------------------------*  $COLOR-SHEME
\*------------------------------------*/
/** Basic colors Fancy generator: https://kuler.adobe.com */
/** Colors for alerts e.g. success, error, info */
/** 3 Shades of Grey */
/*------------------------------------*  $TYPOGRAPHY
\*------------------------------------*/
/** Set the font weight variables according to the font e.g. http://fonts.googleapis.com/css?family=Source+Sans+Pro:200,400,700 In this case set the font weights to 200, 400 and 700 If you set a font weight in your css you must use this variables! e.g. font-weight: $font-a-weight-normal; 1. must be in px, do not use in your styles */
/* [1] */
/* [1] */
/** Font-sizes */
@font-face { font-family: 'Big Noodle Titling'; src: url("../assets/fonts/BigNoodleTitling/330DB4_1_0.woff2") format("woff2"), url("../assets/fonts/BigNoodleTitling/330DB4_1_0.woff") format("woff"), url("../assets/fonts/BigNoodleTitling/330DB4_1_0.ttf") format("truetype"); }

/* roboto-condensed-300 - latin */
@font-face { font-family: 'Roboto Condensed'; font-style: normal; font-weight: 300; src: url("../assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-300.woff2") format("woff2"), url("../assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* roboto-condensed-regular - latin */
@font-face { font-family: 'Roboto Condensed'; font-style: normal; font-weight: 400; src: url("../assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-regular.woff2") format("woff2"), url("../assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* roboto-condensed-700 - latin */
@font-face { font-family: 'Roboto Condensed'; font-style: normal; font-weight: 700; src: url("../assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-700.woff2") format("woff2"), url("../assets/fonts/roboto-condensed-v25-latin/roboto-condensed-v25-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/** Primary font */
/** Secondary font */
/** Anchors */
/** Text selection */
/*------------------------------------*  $BREAKPOINTS
\*------------------------------------*/
/** Breakpoints */
/*------------------------------------*  $SPACING
\*------------------------------------*/
/** Basic spacing */
/*------------------------------------*  $LAYOUT
\*------------------------------------*/
/*------------------------------------*  $COMPONENTS
\*------------------------------------*/
/** Grid */
/** Button */
/** Menu */
/** Island */
/** Media */
/** Skiplinks */
/** Pager */
/** Message */
/** CKEditor */
/** Tabs */
/** Flexslider */
/** Flexslider */
/** Row style */
/*------------------------------------*  SCAFFOLDING
\*------------------------------------*/
/** CONTENTS SYSTEM.............. BASE................ COMPONENTS.......... */
/*------------------------------------*  $SYSTEM
\*------------------------------------*/
/*------------------------------------*  FUNCTIONS
\*------------------------------------*/
/** CONTENTS STRIP-UNITS......... PIXEL2EM............ EM2PIXEL............ EM2REM.............. */
/*------------------------------------*  $STRIP-UNITS
\*------------------------------------*/
/*------------------------------------*  $PIXEL2EM
\*------------------------------------*/
/** Convert a px value to em based on $font-base-size */
/*------------------------------------*  $EM2PIXEL
\*------------------------------------*/
/** Convert a em value to px based on $font-base-size */
/*------------------------------------*  $EM2REM
\*------------------------------------*/
/** Convert a em value to rem */
/*------------------------------------*  $BASE
\*------------------------------------*/
/*------------------------------------*  BASE
\*------------------------------------*/
/** CONTENTS ELEMENTS............ SELECTION........... */
/*------------------------------------*  $ELEMENTS
\*------------------------------------*/
html { font-size: 100%; line-height: 1.5625; }

html, button, input, select, textarea { color: #666666; font-family: "Big Noodle Titling", sans-serif; }

/* A better looking default horizontal rule */
hr { display: block; margin: 1em 0; padding: 0; height: 1px; border: 0; border-top: 1px solid #666666; }

/* Remove the gap between images, videos, audio and canvas and the bottom of their containers: h5bp.com/i/440 */
audio, canvas, img, svg, video { vertical-align: middle; }

/* Remove default fieldset styles. 1. Chrome and Firefox set a `min-width: min-content;` on fieldsets, so we reset that to ensure it behaves more like a standard block element. See https://github.com/twbs/bootstrap/issues/12359. */
fieldset { margin: 0; padding: 0; min-width: 0; /* [1] */ border: 0; }

/* Allow only vertical resizing of textareas. */
textarea { resize: vertical; }

/*------------------------------------*  $SELECTION
\*------------------------------------*/
/* Remove text-shadow in selection highlight: h5bp.com/i These selection rule sets have to be separate. Customize the background color to match your design. */
::-moz-selection { background: #000; color: #fff; text-shadow: none; }

::selection { background: #000; color: #fff; text-shadow: none; }

/*------------------------------------*  FORM
\*------------------------------------*/
/** CONTENTS BASE................ */
/*------------------------------------*  $BASE
\*------------------------------------*/
label { display: block; }

select, textarea, input[type='text'], input[type='password'], input[type='datetime'], input[type='datetime-local'], input[type='date'], input[type='month'], input[type='time'], input[type='week'], input[type='number'], input[type='email'], input[type='url'], input[type='search'], input[type='tel'], input[type='color'] { display: inline-block; padding: 0.5em; max-width: 100%; border: 1px solid #25262d; background-image: none; transition: border-color ease-in-out 0.1s, box-shadow ease-in-out 0.1s; }

select:focus, textarea:focus, input[type='text']:focus, input[type='password']:focus, input[type='datetime']:focus, input[type='datetime-local']:focus, input[type='date']:focus, input[type='month']:focus, input[type='time']:focus, input[type='week']:focus, input[type='number']:focus, input[type='email']:focus, input[type='url']:focus, input[type='search']:focus, input[type='tel']:focus, input[type='color']:focus { outline: none; border-color: #000; box-shadow: 0 0 0.375em #000; }

input[type='radio'], input[type='checkbox'] { margin-top: 1px \9; line-height: normal; }

input[type='radio'] + label, input[type='checkbox'] + label { display: inline-block; }

input[type='file'] { display: block; }

input[type='range'] { display: block; width: 100%; }

select[multiple], select[size] { height: auto; }

input[type='file']:focus, input[type='radio']:focus, input[type='checkbox']:focus { border: 3px solid red; }

textarea { height: auto; }

/*------------------------------------*  LAYOUT
\*------------------------------------*/
/** CONTENTS CONTAINER........... BOX-SIZING-RESET.... */
/*------------------------------------*  $CONTAINER
\*------------------------------------*/
.container, .page-team #cmp--content { margin: 0 auto; padding: 0 1em; max-width: 78em; }

/*------------------------------------*  $BOX-SIZING-RESET
\*------------------------------------*/
html { box-sizing: border-box; }

*, *:before, *:after { box-sizing: inherit; }

/*------------------------------------*  TYPOGRAPHY
\*------------------------------------*/
/*------------------------------------*  $WHITESPACE
\*------------------------------------*/
/** Add whitespace around some block elements */
p, ul, ol, dl, pre, form, table, figure, address, fieldset, blockquote { margin-top: 0; margin-bottom: 1.5625em; }

ul, ol, dl { padding-left: 1.5em; }

/** no margin for nested lists */
li ul, li ol, li dl { margin-bottom: 0; }

/** no margin for nested tables */
table table { margin-bottom: 0; }

/** Normalize default font related tags */
b, strong { font-weight: 700; }

small { font-size: 0.85em; }

/*------------------------------------*  UTILITY
\*------------------------------------*/
/** CONTENTS CLEARFIX............ LIST-RESET.......... SPACING............. VISIBILITY.......... DISPLAY............. */
/*------------------------------------*  CLEARFIX
\*------------------------------------*/
/** Clearfix Contain floats */
.u-cf, .grid, .menu--horizontal .menu__list--level1, .media, .clearfix, .tabs { *zoom: 1; }

.u-cf:before, .grid:before, .menu--horizontal .menu__list--level1:before, .media:before, .clearfix:before, .tabs:before, .u-cf:after, .grid:after, .menu--horizontal .menu__list--level1:after, .media:after, .clearfix:after, .tabs:after { display: table; content: ' '; }

.u-cf:after, .grid:after, .menu--horizontal .menu__list--level1:after, .media:after, .clearfix:after, .tabs:after { clear: both; }

/*------------------------------------*  $LIST-RESET
\*------------------------------------*/
.u-list-reset, .menu__list, .pager, .tabs { padding-left: 0; list-style-type: none; }

/*------------------------------------*  $SPACING
\*------------------------------------*/
/** Bottom */
.u-spacing--bottom--xs { margin-bottom: 0.5em; }

.u-spacing--bottom--s { margin-bottom: 1em; }

.u-spacing--bottom--m { margin-bottom: 1.25em; }

.u-spacing--bottom--l { margin-bottom: 2em; }

.u-spacing--bottom--xl { margin-bottom: 3em; }

.u-spacing--bottom--xxl { margin-bottom: 4em; }

.u-spacing--bottom--xxxl { margin-bottom: 5em; }

.u-spacing--bottom--off { margin-bottom: 0; }

/** Inner */
.u-spacing--inner--both--xs { padding-top: 0.5em; padding-bottom: 0.5em; }

.u-spacing--inner--both--s { padding-top: 1em; padding-bottom: 1em; }

.u-spacing--inner--both--m { padding-top: 1.25em; padding-bottom: 1.25em; }

.u-spacing--inner--both--l { padding-top: 2em; padding-bottom: 2em; }

.u-spacing--inner--both--xl { padding-top: 3em; padding-bottom: 3em; }

.u-spacing--inner--both--xxl { padding-top: 4em; padding-bottom: 4em; }

.u-spacing--inner--both--xxxl { padding-top: 5em; padding-bottom: 5em; }

/*------------------------------------*  $VISIBILITY
\*------------------------------------*/
/** Hide from both screenreaders and browsers: h5bp.com/u */
.u-hidden { display: none !important; visibility: hidden; }

/** Hide only visually, but have it available for screenreaders: h5bp.com/v */
.u-visuallyhidden, .element-invisible, .hide { position: absolute; overflow: hidden; clip: rect(0 0 0 0); margin: -1px; padding: 0; width: 1px; height: 1px; border: 0; }

.u-visuallyhidden.u-focusable:active, .u-focusable.element-invisible:active, .u-focusable.hide:active, .u-visuallyhidden.u-focusable:focus, .u-focusable.element-invisible:focus, .u-focusable.hide:focus { position: static; overflow: visible; clip: auto; margin: 0; width: auto; height: auto; }

/** Hide visually and from screenreaders, but maintain layout */
.u-invisible { visibility: hidden; }

/*------------------------------------*  $DISPLAY
\*------------------------------------*/
.u-display-block { display: block; }

.u-display-inline { display: inline; }

.u-display-inline-block { display: inline-block; }

/*------------------------------------*  $COMPONENTS
\*------------------------------------*/
/*------------------------------------*  GRID
\*------------------------------------*/
/** CONTENTS BASE................ SMALL............... MEDIUM.............. LARGE............... XLARGE.............. <div class="grid"> <div class="grid__item--6 grid__item--m--3"> [optional]<div class="grid__inner"></div>[/optional] </div> <div class="grid__item--6 grid__item--m--3"> [optional]<div class="grid__inner"></div>[/optional] </div> <div class="grid__item--12 grid__item--s--6"> [optional]<div class="grid__inner"></div>[/optional] </div> <div class="grid__item--12 grid__item--s--6"> [optional]<div class="grid__inner"></div>[/optional] </div> </div> */
/*------------------------------------*  $BASE
\*------------------------------------*/
/** Styles for all devices */
@media (max-width: 719px) { .grid { margin-left: -1em; } }

[class*='grid__item'] { float: left; width: 100%; }

@media (max-width: 719px) { [class*='grid__item'] { padding-left: 1em; } }

.grid__item--1 { width: 8.3333333%; }

.grid__item--2 { width: 16.6666667%; }

.grid__item--3 { width: 25%; }

.grid__item--4 { width: 33.3333333%; }

.grid__item--5 { width: 41.6666667%; }

.grid__item--6 { width: 50%; }

.grid__item--7 { width: 58.3333333%; }

.grid__item--8 { width: 66.6666667%; }

.grid__item--9 { width: 75%; }

.grid__item--10 { width: 83.3333333%; }

.grid__item--11 { width: 91.6666667%; }

.grid__item--12 { width: 100%; }

/*------------------------------------*    $SMALL \*------------------------------------*/
@media (min-width: 480px) { .grid__item--s--1 { width: 8.3333333%; }
  .grid__item--s--2 { width: 16.6666667%; }
  .grid__item--s--3 { width: 25%; }
  .grid__item--s--4 { width: 33.3333333%; }
  .grid__item--s--5 { width: 41.6666667%; }
  .grid__item--s--6 { width: 50%; }
  .grid__item--s--7 { width: 58.3333333%; }
  .grid__item--s--8 { width: 66.6666667%; }
  .grid__item--s--9 { width: 75%; }
  .grid__item--s--10 { width: 83.3333333%; }
  .grid__item--s--11 { width: 91.6666667%; }
  .grid__item--s--12 { width: 100%; } }

/*------------------------------------*    $MEDIUM \*------------------------------------*/
@media (min-width: 720px) { .grid { margin-left: -1.25em; }
  [class*='grid__item'] { padding-left: 1.25em; }
  .grid__item--m--1 { width: 8.3333333%; }
  .grid__item--m--2 { width: 16.6666667%; }
  .grid__item--m--3 { width: 25%; }
  .grid__item--m--4 { width: 33.3333333%; }
  .grid__item--m--5 { width: 41.6666667%; }
  .grid__item--m--6 { width: 50%; }
  .grid__item--m--7 { width: 58.3333333%; }
  .grid__item--m--8 { width: 66.6666667%; }
  .grid__item--m--9 { width: 75%; }
  .grid__item--m--10 { width: 83.3333333%; }
  .grid__item--m--11 { width: 91.6666667%; }
  .grid__item--m--12 { width: 100%; } }

/*------------------------------------*    $LARGE \*------------------------------------*/
@media (min-width: 960px) { .grid { margin-left: -1.25em; }
  [class*='grid__item'] { padding-left: 1.25em; }
  .grid__item--l--1 { width: 8.3333333%; }
  .grid__item--l--2 { width: 16.6666667%; }
  .grid__item--l--3 { width: 25%; }
  .grid__item--l--4 { width: 33.3333333%; }
  .grid__item--l--5 { width: 41.6666667%; }
  .grid__item--l--6 { width: 50%; }
  .grid__item--l--7 { width: 58.3333333%; }
  .grid__item--l--8 { width: 66.6666667%; }
  .grid__item--l--9 { width: 75%; }
  .grid__item--l--10 { width: 83.3333333%; }
  .grid__item--l--11 { width: 91.6666667%; }
  .grid__item--l--12 { width: 100%; } }

/*------------------------------------*    $XLARGE \*------------------------------------*/
@media (min-width: 1200px) { .grid { margin-left: -1.25em; }
  [class*='grid__item'] { padding-left: 1.25em; }
  .grid__item--xl--1 { width: 8.3333333%; }
  .grid__item--xl--2 { width: 16.6666667%; }
  .grid__item--xl--3 { width: 25%; }
  .grid__item--xl--4 { width: 33.3333333%; }
  .grid__item--xl--5 { width: 41.6666667%; }
  .grid__item--xl--6 { width: 50%; }
  .grid__item--xl--7 { width: 58.3333333%; }
  .grid__item--xl--8 { width: 66.6666667%; }
  .grid__item--xl--9 { width: 75%; }
  .grid__item--xl--10 { width: 83.3333333%; }
  .grid__item--xl--11 { width: 91.6666667%; }
  .grid__item--xl--12 { width: 100%; } }

/*------------------------------------*  BUTTON
\*------------------------------------*/
/** CONTENTS BASE................ SIZES............... FONT-SIZES.......... FUNCTIONS........... */
/*------------------------------------*  $BASE
\*------------------------------------*/
.button, .flex-caption a { display: inline-block; margin: 0; padding: 0.5em 1em; border: none; background: #000; vertical-align: top; white-space: nowrap; font-size: 100%; font-family: inherit; cursor: pointer; transition: background, 0.3s; }

.button, .flex-caption a, .button:hover, .flex-caption a:hover, .button:focus, .flex-caption a:focus, .button:active, .flex-caption a:active, .button:visited, .flex-caption a:visited { outline: none; color: #fff; text-decoration: none; }

.button:hover, .flex-caption a:hover, .button:focus, .flex-caption a:focus, .button:active, .flex-caption a:active { background: #4d4d4d; }

/*------------------------------------*  $SIZES
\*------------------------------------*/
/** Button size modifiers. These all follow the same sizing rules as above; text is 1em, space around it remains uniform. */
/*------------------------------------*  $FONT-SIZES
\*------------------------------------*/
/** Button font-size modifiers. */
/*------------------------------------*  $FUNCTIONS
\*------------------------------------*/
/*------------------------------------*  MENU
\*------------------------------------*/
/** CONTENTS DEFAULT............. OFF CANVAS.......... HORIZONTAL.......... VERTICAL............ */
/*------------------------------------*  $DEFAULT
\*------------------------------------*/
/** 1. Set position root for off canvas menu 2. Set position root for sub menus (dropdowns) */
html { position: relative; /* [1] */ }

.menu__list { margin-bottom: 0; }

.menu__list--level1 { transition: transform 0.2s; }

.menu__item { position: relative; /* [2] */ }

.menu__link { display: block; text-decoration: none; }

.menu__checkbox { display: none; }

@media (min-width: 720px) { .menu__controls { display: none; } }

/*------------------------------------*  $OFF CANVAS
\*------------------------------------*/
.menu--off-canvas .menu__controls { position: absolute; top: 0; left: 0; z-index: 1; display: block; width: 100%; }

.menu--off-canvas .menu__list--level1 { position: absolute; top: 0; bottom: 0; left: 0; padding-top: 3em; max-width: 20em; width: 70%; transform: translate(-100%, 0); }

.menu--off-canvas .menu__checkbox:checked + .menu__list--level1 { transform: translate(0, 0); }

/*------------------------------------*  $HORIZONTAL
\*------------------------------------*/
@media (max-width: 719px) { .menu--horizontal .menu__list--level1 { height: 0; transform: scale(1, 0); transform-origin: 0 0; }
  .menu--horizontal .menu__checkbox:checked + .menu__list--level1 { height: auto; transform: scale(1, 1); } }

@media (min-width: 720px) { .menu--horizontal .menu__list--level1 .menu__list { position: absolute; visibility: hidden; opacity: 0; transition: opacity 0.2s; }
  .menu--horizontal .menu__list--level1 .menu__list .menu__list { top: 0; left: 100%; }
  .menu--horizontal .menu__item:hover > .menu__list, .menu--horizontal .menu__item:focus > .menu__list { visibility: visible; opacity: 1; }
  .menu--horizontal .menu__item--level1 { float: left; } }

/*------------------------------------*  $VERTICAL
\*------------------------------------*/
/*------------------------------------*  MEDIA
\*------------------------------------*/
/** CONTENTS BASE................ Place any image- and text-like content side-by-side, as per: http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code/ <div class="media"> <a href="#" class="media__img"> <img src="pony.png"> </a> [optional] <div class="media__img--rev"> <img src="pony2.png"> </div> [/optional] <div class="media__body"> <p>Lorem Ipsum</p> </div> </div> */
/*------------------------------------*  $BASE
\*------------------------------------*/
/** Reset media styling on small screens */
@media (max-width: 719px) { .media--mobile-reset .media__img { float: none; margin-right: 0; }
  .media--mobile-reset .media__img--rev { float: none; margin-left: 0; } }

.media__img { float: left; margin-right: 1.25em; }

/** Reversed image location (right instead of left). */
.media__img--rev { float: right; margin-left: 1.25em; }

.media__img img, .media__img--rev img { display: block; }

.media__body { overflow: hidden; }

/** Colorbox Core Style: The following CSS is consistent between example themes and should not be altered. */
#colorbox, #cboxOverlay, #cboxWrapper { position: absolute; top: 0; left: 0; z-index: 9999; }

#cboxOverlay { position: fixed; width: 100%; height: 100%; }

#cboxMiddleLeft, #cboxBottomLeft { clear: left; }

#cboxContent { position: relative; }

#cboxLoadedContent { overflow: auto; -webkit-overflow-scrolling: touch; }

#cboxTitle { margin: 0; }

#cboxLoadingOverlay, #cboxLoadingGraphic { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

/** These elements are buttons, and may need to have additional styles reset to avoid unwanted base styles. */
#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow { border: 0; padding: 0; margin: 0; overflow: visible; width: auto; background: none; cursor: pointer; }

#cboxPrevious, #cboxNext, #cboxClose { position: absolute; font-family: 'themify'; speak: none; color: #fff; }

#cboxPrevious:after, #cboxNext:after, #cboxClose:after { display: block; font-size: 2em; }

#cboxClose { top: -3em; right: 0; }

#cboxClose:after { content: "\e646"; }

#cboxNext { right: 0; padding-top: 0.25rem; }

#cboxNext:after { content: "\e649"; }

#cboxPrevious { padding-top: 0.25rem; }

#cboxPrevious:after { content: "\e64a"; }

#cboxCurrent { display: none !important; }

/** Avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
#cboxPrevious:active, #cboxNext:active, #cboxClose:active, #cboxSlideshow:active { outline: 0; }

.cboxPhoto { float: left; margin: auto; border: 0; display: block; max-width: none; }

.cboxIframe { width: 100%; height: 100%; display: block; border: 0; }

#colorbox, #cboxContent, #cboxLoadedContent { box-sizing: content-box; }

#cboxOverlay { background: #000; }

#colorbox { outline: 0; }

#cboxLoadingOverlay { background: #fff; }

/*------------------------------------*  FORM
\*------------------------------------*/
/** CONTENTS BASE................ DATE................ */
/*------------------------------------*  $BASE
\*------------------------------------*/
.form__item { margin-bottom: 0.5em; }

.form__item .form__item { margin-bottom: 0; }

.form__wrapper { margin-bottom: 0; }

.form__label, .form__fieldset-legend { font-weight: 400; }

/*------------------------------------*  $DATE
\*------------------------------------*/
.form__item--date-select .form__item { display: inline-block; margin-right: 0.5em; margin-bottom: 0; }

.form__item--date-select .form__label { font-weight: 300; }

.container--white { background: #fff; }

.container--no-spacing { padding: 0; }

.container--l { max-width: 102rem; }

.container--half { max-width: 28rem; margin-left: 0; }

.region--sidebar1 { margin-top: 5rem; }

.links.inline .translation_en { display: none; }

/** Responsiv images */
img { width: auto; max-width: 100%; height: auto; }

/*------------------------------------*  TYPOGRAPHY
\*------------------------------------*/
/** CONTENTS HEADLINES........... ANCHORS............. WHITESPACE.......... */
body { font-weight: 300; }

/*------------------------------------*  $HEADLINES
\*------------------------------------*/
/** Reset margin on headlines */
#title--main { text-align: center; }

h1, h2, .field--paragraphs_item-custtomer_logos--field-custmer-block-title, h3, .request-callback span, h4 { margin-top: 0; margin-bottom: 0.5em; color: #000; font-weight: 300; font-family: "Big Noodle Titling", sans-serif; line-height: 1; letter-spacing: 0.5px; }

h1 { font-size: 2.25em; }

@media (min-width: 720px) { h1 { font-size: 3em; } }

h2, .field--paragraphs_item-custtomer_logos--field-custmer-block-title { font-size: 3em; }

h3, .request-callback span { font-size: 1.75em; color: #f58220; margin-bottom: .5rem; }

h4 { font-size: 1.3em; }

h5 { margin: 0; font-size: 1em; }

h6 { margin: 0; font-size: 1em; }

/*------------------------------------*  $ANCHORS
\*------------------------------------*/
/** <span class="a">readmore...</span> */
a, .a { color: #000; text-decoration: none; outline: none !important; }

a:visited, .a:visited { color: #000; }

a:hover, a:focus, .a:hover, .a:focus { color: #000; text-decoration: underline; }

/** Reversed link behaviour */
.a--reversed { text-decoration: underline; }

.a--reversed:hover, .a--reversed:focus { text-decoration: none; }

body { font-family: "Roboto Condensed", sans-serif; }

ul { text-align: left; }

/*------------------------------------*  UTILITY
\*------------------------------------*/
.intro { font-size: 1.3em; }

.intro p { margin-bottom: 1.5rem; }

.font-a-family, .font-a-family a { font-family: "Big Noodle Titling", sans-serif; }

.font-b-family, .font-b-family a { font-family: "Roboto Condensed", sans-serif; }

.letter-spacing { letter-spacing: 0.15em; }

.top-margin { margin-top: 6rem; }

.bottom-margin { margin-bottom: 6rem; }

/* Text */
.text-left { text-align: left; }

.text-right { text-align: right; }

.text-center { text-align: center; }

.text-justify { text-align: justify; }

.text-uppercase { text-transform: uppercase; }

/* Positions */
.fixed { position: fixed; }

.relative { position: relative; }

.absolute { position: absolute; }

.static { position: static; }

/* Float */
.left { float: left; }

.right { float: right; }

/* Anchor */
.block-link { text-decoration: none; color: #666666; display: block; }

.block-link:hover, .block-link:focus, .block-link:visited { text-decoration: none; color: #666666; }

/* Responsive visibily */
@media (max-width: 479px) { .hide-s { display: none; visibility: hidden; } }

.show-s { display: none; visibility: hidden; }

@media (max-width: 479px) { .show-s { display: block; visibility: visible; } }

@media (max-width: 719px) { .hide-m { display: none; visibility: hidden; } }

.show-m { display: none; visibility: hidden; }

@media (max-width: 719px) { .show-m { display: block; visibility: visible; } }

.js .hide-js { display: none; visibility: hidden; }

.vertical-align-center { display: flex; align-items: center; height: 100%; justify-content: center; }

.request-callback { background-color: #25262d; text-align: center; padding: 2rem 0; display: flex; align-items: center; justify-content: center; }

.request-callback span { margin-right: 2rem; color: #fff; }

.request-callback .button, .request-callback .flex-caption a, .flex-caption .request-callback a { margin-top: 0; }

@media (min-width: 1000px) { .block--teasers-teaser-front { background: url("/sites/all/themes/roromedia/assets/icon_bg.svg") no-repeat 0 0; background-size: 50%; background-position-x: 135%; padding-bottom: 6rem; margin-bottom: 0 !important; } }

.button, .flex-caption a { font-family: "Big Noodle Titling", sans-serif; font-size: 1.5rem; padding: 0.1em 0.7em 0.2em; margin-top: 1em; background: transparent; border: 3px solid #f58220; color: #f58220 !important; }

.button:hover, .flex-caption a:hover, .button:focus, .flex-caption a:focus { background: #f58220; color: #fff !important; border-color: transparent; }

.button--alt { font-family: "Big Noodle Titling", sans-serif; font-weight: 300; text-transform: uppercase; background: #fcf8df; border: 1px solid #fcf8df; transition: all, 0.3s; }

.button--alt, .button--alt:visited { color: #000; }

.button--alt:hover, .button--alt:focus { text-decoration: none; background: #fff; color: #000; border-color: #000; }

.button--outline { font-weight: 300; text-transform: uppercase; background: transparent; border: 3px solid white; color: #fff; transition: all, 0.3s; }

.button--outline:hover, .button--outline:focus { text-decoration: none; background: #fff; color: #000; }

.button--outline--dark { background: transparent; border: 3px solid black; color: #000; }

.button--outline--dark:hover, .button--outline--dark:focus { background: #f58220; color: #fff; border-color: transparent; }

#block--callback-service { padding: 40px; box-shadow: 2px 2px 20px 0px #efefef; min-height: 480px; margin-bottom: 100px; }

#block--callback-service #edit-field-form-contact-quelle { display: none !important; }

#block--callback-service #callback-service-entityform-edit-form > div { position: relative; }

#block--callback-service #callback-service-entityform-edit-form > div #edit-field-form-contact-name, #block--callback-service #callback-service-entityform-edit-form > div #edit-field-form-contact-email, #block--callback-service #callback-service-entityform-edit-form > div #edit-field-form-contact-telefon { width: 48%; position: absolute; left: 0; }

#block--callback-service #callback-service-entityform-edit-form > div #edit-field-form-contact-message { position: absolute; width: 48%; right: 0; }

#block--callback-service #callback-service-entityform-edit-form > div #edit-field-form-contact-email { top: 80px; }

#block--callback-service #callback-service-entityform-edit-form > div #edit-field-form-contact-telefon { top: 160px; }

#block--callback-service #callback-service-entityform-edit-form > div textarea { min-height: 197px; }

#block--callback-service #callback-service-entityform-edit-form > div .captcha { position: absolute; top: 260px; left: 130px; }

#block--callback-service #callback-service-entityform-edit-form > div #edit-actions { position: absolute; top: 240px; }

@media (max-width: 550px) { #block--callback-service #callback-service-entityform-edit-form > div div { width: 100% !important; position: relative !important; top: initial !important; } }

/*------------------------------------*  CKEDITOR
\*------------------------------------*/
/** CONTENTS BASE................ */
/*------------------------------------*  $BASE
\*------------------------------------*/
.cke_show_borders { margin: 1em; }

.paragraphs-item-custtomer-logos .field-customer { display: flex; justify-content: center; flex-wrap: wrap; }

.paragraphs-item-custtomer-logos article { width: 20%; float: left; height: 150px; display: flex; justify-content: center; }

.paragraphs-item-custtomer-logos article header h2, .paragraphs-item-custtomer-logos article header .field--paragraphs_item-custtomer_logos--field-custmer-block-title { display: none; }

.paragraphs-item-custtomer-logos article h3, .paragraphs-item-custtomer-logos article .request-callback span, .request-callback .paragraphs-item-custtomer-logos article span { display: none; }

.paragraphs-item-custtomer-logos article .form__item { display: none; }

.paragraphs-item-custtomer-logos article .field-customer-show-on-frontpage { display: none; }

.paragraphs-item-custtomer-logos article .field-customer-logo { text-align: center; display: -ms-flexbox; display: flex; -ms-flex-align: center; align-items: center; height: 100%; -ms-flex-pack: center; justify-content: center; }

.paragraphs-item-custtomer-logos article .field-customer-logo img { filter: grayscale(100%); }

@media only screen and (max-width: 992px) { .paragraphs-item-custtomer-logos article { width: 50%; float: left; } }

@media only screen and (max-width: 500px) { .paragraphs-item-custtomer-logos article { width: 100%; float: left; } }

.field--paragraphs_item-custtomer_logos--field-custmer-block-title { text-align: center; margin-bottom: 1em; }

.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; }

.embed-container iframe, .embed-container object, .embed-container video, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

.page-node--312 h1 { position: relative; font-size: 5em; margin-bottom: 0; animation: pulse 15s; }

@media (min-width: 720px) { .page-node--312 h1 { font-size: 12em; } }

.page-node--312 .ti-unlink { position: absolute; top: 0; display: block; font-size: 4rem; }

@media (min-width: 720px) { .page-node--312 .ti-unlink { position: absolute; display: inline-block; } }

@keyframes pulse { 0% { transform: scale(1); }
  100% { transform: scale(1.1); } }

.field-download .field__item { display: flex; justify-content: space-between; font-family: "Big Noodle Titling", sans-serif; align-items: center; padding: 0.75em 0.5em 1em 0.5em; border-bottom: 1px solid #e6e6e6; background: #f2f2f2; }

.field-download .field__item [class^="ti-"] { font-size: 1.3em; margin-right: 0.5em; position: relative; top: 2px; }

.field-download .field__item:last-child { border-bottom: none; }

.field-download .field__item a { transition: opacity, 0.3s; font-size: 1.5em; }

.field-download .field__item a:hover, .field-download .field__item a:focus { text-decoration: none; opacity: 0.7; }

span.filesize { font-size: 1.5em; }

.filter { list-style: none; padding: 0; border-bottom: 0.1em solid #e9e9e9; }

.filter__item { display: inline-block; font-size: 1.2rem; }

.filter__filter { padding: 0.5rem 1.25rem; display: block; border-bottom: 0.25rem solid transparent; }

.filter__filter:hover, .filter__filter:focus { text-decoration: none; }

.filter__filter.active { border-bottom-color: #f58220; }

/* Browser Resets
*********************************/
.flex-container a:active, .flexslider a:active, .flex-container a:focus, .flexslider a:focus { outline: none; }

.slides, .flex-control-nav, .flex-direction-nav { margin: 0; padding: 0; list-style: none; }

/* FlexSlider Necessary Styles
*********************************/
.flexslider { margin: 0; padding: 0; }

.not-front .flexslider { max-height: 540px; }

.not-front .flexslider .slides img { max-height: 540px; float: right; width: auto; }

@media (min-width: 720px) { .view__field--field-slide-image-1__content { max-height: 40em; overflow: hidden; }
  .not-front .flexslider { max-height: 40em; }
  .not-front .flexslider .slides img { height: auto; }
  .flexslider .slides > li { height: 40em; } }

.flexslider .slides > li { display: none; overflow: hidden; -webkit-backface-visibility: hidden; /* &:after { display: block; height: auto; content: url(../assets/bend.svg); position: absolute; bottom: -44%; right: -30%; left: -60%; }*/ }

/* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img { display: block; width: 100%; height: auto; }

.flex-pauseplay span { text-transform: capitalize; }

/* Clearfix for the .slides element */
.slides:after { display: block; visibility: hidden; clear: both; height: 0; content: '\0020'; line-height: 0; }

html[xmlns] .slides { display: block; }

* html .slides { height: 1%; }

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you include js that eliminates this class on page load */
.no-js .slides > li:first-child { display: block; }

.flexslider { position: relative; overflow: hidden; }

.flexslider .view__row { position: relative; }

.flexslider .slides > li { position: relative; }

.flexslider:hover .flex-prev, .flexslider:hover .flex-next { opacity: 1; }

.flex-control-paging a { cursor: pointer; }

/** Controls */
.flex-prev, .flex-next { position: absolute; top: 50%; transform: translateY(-50%); opacity: 0; transition: opacity 0.2s; margin: 0 0.5em; }

.flex-prev span, .flex-prev .fa, .flex-next span, .flex-next .fa { font-size: 3em; text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.35); }

.flex-prev, .flex-prev:hover, .flex-prev:visited, .flex-prev:focus, .flex-next, .flex-next:hover, .flex-next:visited, .flex-next:focus { color: #fff; text-decoration: none; }

.flex-next { right: 0; }

.flex-disabled { display: none; }

.flex-control-nav { width: 100%; position: absolute; height: auto; padding: 2px 0; text-align: center; }

.flex-control-nav li, .flex-control-nav a { display: inline-block; }

.flex-control-nav a { margin: 0 0.2em; width: 0.8em; height: 0.8em; background: #f58220; border-radius: 100%; text-decoration: none; text-indent: -999em; cursor: pointer; line-height: 71%; }

.flex-control-nav .flex-active { background: #000; }

.flex-caption .cmp--hero-text { margin-top: 1em; color: #000; }

.flex-caption .slide-bright-font, .flex-caption .slide-bright-font h1, .flex-caption .slide-bright-font h2, .flex-caption .slide-bright-font .field--paragraphs_item-custtomer_logos--field-custmer-block-title, .flex-caption .slide-bright-font h3, .flex-caption .slide-bright-font .request-callback span, .request-callback .flex-caption .slide-bright-font span, .flex-caption .slide-bright-font h4, .flex-caption .hero-slide--bright-text, .flex-caption .hero-slide--bright-text h1, .flex-caption .hero-slide--bright-text h2, .flex-caption .hero-slide--bright-text .field--paragraphs_item-custtomer_logos--field-custmer-block-title, .flex-caption .hero-slide--bright-text h3, .flex-caption .hero-slide--bright-text .request-callback span, .request-callback .flex-caption .hero-slide--bright-text span, .flex-caption .hero-slide--bright-text h4 { color: #fff; }

.flex-caption .slide-bright-font .slide-bright-font, .flex-caption .slide-bright-font .slide-bright-font h1, .flex-caption .slide-bright-font .slide-bright-font h2, .flex-caption .slide-bright-font .slide-bright-font .field--paragraphs_item-custtomer_logos--field-custmer-block-title, .flex-caption .slide-bright-font .slide-bright-font h3, .flex-caption .slide-bright-font .slide-bright-font .request-callback span, .request-callback .flex-caption .slide-bright-font .slide-bright-font span, .flex-caption .slide-bright-font .slide-bright-font h4, .flex-caption .hero-slide--bright-text .slide-bright-font, .flex-caption .hero-slide--bright-text .slide-bright-font h1, .flex-caption .hero-slide--bright-text .slide-bright-font h2, .flex-caption .hero-slide--bright-text .slide-bright-font .field--paragraphs_item-custtomer_logos--field-custmer-block-title, .flex-caption .hero-slide--bright-text .slide-bright-font h3, .flex-caption .hero-slide--bright-text .slide-bright-font .request-callback span, .request-callback .flex-caption .hero-slide--bright-text .slide-bright-font span, .flex-caption .hero-slide--bright-text .slide-bright-font h4 { text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.35); }

.flex-caption .slide-bright-font a, .flex-caption .hero-slide--bright-text a { text-shadow: none; }

@media (min-width: 720px) { .flex-caption .slide-bright-font.slide-halfwidth, .flex-caption .slide-bright-font.slide-halfwidth h2, .flex-caption .slide-bright-font.slide-halfwidth .field--paragraphs_item-custtomer_logos--field-custmer-block-title, .flex-caption .slide-bright-font.slide-halfwidth h3, .flex-caption .slide-bright-font.slide-halfwidth .request-callback span, .request-callback .flex-caption .slide-bright-font.slide-halfwidth span, .flex-caption .slide-bright-font.slide-halfwidth h4, .flex-caption .hero-slide--bright-text.slide-halfwidth, .flex-caption .hero-slide--bright-text.slide-halfwidth h2, .flex-caption .hero-slide--bright-text.slide-halfwidth .field--paragraphs_item-custtomer_logos--field-custmer-block-title, .flex-caption .hero-slide--bright-text.slide-halfwidth h3, .flex-caption .hero-slide--bright-text.slide-halfwidth .request-callback span, .request-callback .flex-caption .hero-slide--bright-text.slide-halfwidth span, .flex-caption .hero-slide--bright-text.slide-halfwidth h4 { color: #666666; text-shadow: none; } }

.flex-caption h1, .flex-caption h2, .flex-caption .field--paragraphs_item-custtomer_logos--field-custmer-block-title, .flex-caption h3, .flex-caption .request-callback span, .request-callback .flex-caption span, .flex-caption h4 { font-family: "Big Noodle Titling", sans-serif; font-weight: 300; margin: 0; line-height: 1; }

.flex-caption h3, .flex-caption .request-callback span, .request-callback .flex-caption span { margin-top: -0.7rem; }

.flex-caption .slide-halfwidth { position: absolute; z-index: 1; width: 50%; top: 2em; padding-left: 3em; }

.flex-caption .slide-halfwidth .inner { max-width: 20em; }

@media (max-width: 719px) { .flex-caption .slide-halfwidth { width: 100%; } }

@media (min-width: 480px) { .flex-caption .slide-halfwidth { top: 6em; padding-left: 6em; } }

.flex-caption .slide-halfwidth blockquote { border: 0; padding: 0; }

.flex-caption .slide-halfwidth blockquote h4, .flex-caption .slide-halfwidth blockquote h5 { margin-bottom: 0; font-size: 1em; }

.flex-caption .slide-halfwidth blockquote em { margin-top: 1em; font-size: 1rem; }

@media (min-width: 480px) { .flex-caption .slide-halfwidth blockquote em { font-size: 1.2rem; } }

@media (min-width: 1060px) { .flex-caption .slide-halfwidth blockquote em { font-size: 1.4rem; } }

.flex-caption .slide-fullwidth, .flex-caption .slide-fullwidth-narrow { position: absolute; width: 100%; padding: 0 2em; top: 0; bottom: 0; z-index: 200; }

.flex-caption .slide-fullwidth > .container, .flex-caption .page-team .slide-fullwidth > #cmp--content, .page-team .flex-caption .slide-fullwidth > #cmp--content, .flex-caption .slide-fullwidth-narrow > .container, .flex-caption .page-team .slide-fullwidth-narrow > #cmp--content, .page-team .flex-caption .slide-fullwidth-narrow > #cmp--content { display: flex; align-items: center; height: 100%; }

.flex-caption .slide-fullwidth h1, .flex-caption .slide-fullwidth h2, .flex-caption .slide-fullwidth .field--paragraphs_item-custtomer_logos--field-custmer-block-title, .flex-caption .slide-fullwidth h3, .flex-caption .slide-fullwidth .request-callback span, .request-callback .flex-caption .slide-fullwidth span, .flex-caption .slide-fullwidth-narrow h1, .flex-caption .slide-fullwidth-narrow h2, .flex-caption .slide-fullwidth-narrow .field--paragraphs_item-custtomer_logos--field-custmer-block-title, .flex-caption .slide-fullwidth-narrow h3, .flex-caption .slide-fullwidth-narrow .request-callback span, .request-callback .flex-caption .slide-fullwidth-narrow span { margin-bottom: 0.1em; }

.flex-caption .slide-fullwidth h1, .flex-caption .slide-fullwidth h2, .flex-caption .slide-fullwidth .field--paragraphs_item-custtomer_logos--field-custmer-block-title, .flex-caption .slide-fullwidth-narrow h1, .flex-caption .slide-fullwidth-narrow h2, .flex-caption .slide-fullwidth-narrow .field--paragraphs_item-custtomer_logos--field-custmer-block-title { font-size: 1rem; letter-spacing: 0.05rem; }

.flex-caption .slide-fullwidth h3, .flex-caption .slide-fullwidth .request-callback span, .request-callback .flex-caption .slide-fullwidth span, .flex-caption .slide-fullwidth-narrow h3, .flex-caption .slide-fullwidth-narrow .request-callback span, .request-callback .flex-caption .slide-fullwidth-narrow span { font-size: 1rem; }

@media (min-width: 480px) { .flex-caption .slide-fullwidth h1, .flex-caption .slide-fullwidth h2, .flex-caption .slide-fullwidth .field--paragraphs_item-custtomer_logos--field-custmer-block-title, .flex-caption .slide-fullwidth-narrow h1, .flex-caption .slide-fullwidth-narrow h2, .flex-caption .slide-fullwidth-narrow .field--paragraphs_item-custtomer_logos--field-custmer-block-title { font-size: 1.3rem; }
  .flex-caption .slide-fullwidth h3, .flex-caption .slide-fullwidth .request-callback span, .request-callback .flex-caption .slide-fullwidth span, .flex-caption .slide-fullwidth-narrow h3, .flex-caption .slide-fullwidth-narrow .request-callback span, .request-callback .flex-caption .slide-fullwidth-narrow span { font-size: 1rem; } }

@media (min-width: 720px) { .flex-caption .slide-fullwidth h1, .flex-caption .slide-fullwidth h2, .flex-caption .slide-fullwidth .field--paragraphs_item-custtomer_logos--field-custmer-block-title, .flex-caption .slide-fullwidth-narrow h1, .flex-caption .slide-fullwidth-narrow h2, .flex-caption .slide-fullwidth-narrow .field--paragraphs_item-custtomer_logos--field-custmer-block-title { font-size: 2rem; }
  .flex-caption .slide-fullwidth h3, .flex-caption .slide-fullwidth .request-callback span, .request-callback .flex-caption .slide-fullwidth span, .flex-caption .slide-fullwidth-narrow h3, .flex-caption .slide-fullwidth-narrow .request-callback span, .request-callback .flex-caption .slide-fullwidth-narrow span { font-size: 1.5rem; } }

@media (min-width: 960px) { .flex-caption .slide-fullwidth h1, .flex-caption .slide-fullwidth h2, .flex-caption .slide-fullwidth .field--paragraphs_item-custtomer_logos--field-custmer-block-title, .flex-caption .slide-fullwidth-narrow h1, .flex-caption .slide-fullwidth-narrow h2, .flex-caption .slide-fullwidth-narrow .field--paragraphs_item-custtomer_logos--field-custmer-block-title { font-size: 7.5rem; }
  .flex-caption .slide-fullwidth h3, .flex-caption .slide-fullwidth .request-callback span, .request-callback .flex-caption .slide-fullwidth span, .flex-caption .slide-fullwidth-narrow h3, .flex-caption .slide-fullwidth-narrow .request-callback span, .request-callback .flex-caption .slide-fullwidth-narrow span { font-size: 4.5rem; } }

#flexslider-1 { opacity: 0; transition: opacity, 0.3s; }

#flexslider-1.is-ready { opacity: 1; }

.region--footer { border-top: 0.1em solid #e9e9e9; padding-top: 2em; padding-bottom: 3em; }

.block--3 { display: flex; justify-content: center; align-items: center; text-align: center; flex-wrap: wrap; }

.block--3 > a, .block--3 > div { width: 100%; text-align: center; margin-bottom: 1em; }

@media (min-width: 960px) { .block--3 { flex-wrap: nowrap; justify-content: space-between; }
  .block--3 > a { width: 9.5em; margin-bottom: 0; }
  .block--3 > div { width: auto; margin-bottom: 0; } }

.block--3 a { color: #6a6a6a; transition: color, 0.1s; }

.block--3 h4, .block--3 h5 { margin-bottom: 0; font-family: "Big Noodle Titling", sans-serif; letter-spacing: 0.15em; color: #6a6a6a; font-size: 1.2em; font-weight: 400; line-height: 1.1em; }

.block--3 h4 { font-weight: 700; }

.footer-facebook-link { font-size: 1.2em; }

.footer-facebook-link span { background: #e5e5e5; transition: background, 0.2s; border-radius: 50%; height: 2.5em; width: 2.5em; display: inline-block; line-height: 2.5em; }

@media (min-width: 960px) { .footer-facebook-link span { float: right; } }

.footer-facebook-link:hover, .footer-facebook-link:focus { text-decoration: none; color: #fff; }

.footer-facebook-link:hover span, .footer-facebook-link:focus span { background: #3b5998; }

.view--customers--block__content { display: flex; justify-content: space-between; flex-wrap: wrap; }

.view--customers .view__row { margin: 20px 0; }

.view--customers .view__row img { padding: 0em !important; }

#block--customers-block { margin-bottom: 80px; }

select, input[type="text"], input[type="email"], input[type="password"], textarea { border: 1px solid #cdcdce; width: 100%; background: transparent; margin-bottom: 0.5em; font-family: "Roboto Condensed", sans-serif; }

input:focus, input[type="text"]:focus, input[type="email"]:focus, input[type="password"]:focus, select:focus, textarea:focus, button:focus { outline: none; box-shadow: none; }

.form__label { font-family: "Big Noodle Titling", sans-serif; font-weight: 300; font-size: 1.5em; color: black; letter-spacing: 0.5px; }

.block--contact input[type="text"], .block--contact input[type="email"], .block--contact input[type="password"], .block--contact textarea { background: rgba(255, 255, 255, 0.5); }

.fullwidth-background-image .image-container { width: 100%; }

@media (min-width: 720px) { .fullwidth-background-image .image-container { height: 36em; } }

@media (min-width: 720px) { .fullwidth-background-image { display: flex; margin-bottom: 6em; }
  .fullwidth-background-image .image-container { width: 60%; height: auto; } }

.fullwidth-background-image .background-image-holder { background: #cccccc no-repeat center center; background-size: contain; height: 100%; }

@media (min-width: 720px) { .fullwidth-background-image .background-image-holder { background-size: cover; } }

@media (min-width: 720px) { .fullwidth-background-image .mobile-only { display: none; } }

@media (max-width: 720px) { .fullwidth-background-image .desktop-only { display: none; } }

.fullwidth-background-image .content { background: #f2f2f2; padding: 3em 0 3em 3%; }

@media (min-width: 720px) { .fullwidth-background-image .content { padding: 5em 0 5em 3%; width: 45%; } }

.view--news .fullwidth-background-image .content { background-color: #f4f4f4; }

.view__row--even .fullwidth-background-image, .fullwidth-background-image.fullwidth-background-image--paragraph:nth-child(even), .field__item.even .fullwidth-background-image { flex-direction: row-reverse; }

.view__row--even .fullwidth-background-image .content, .fullwidth-background-image.fullwidth-background-image--paragraph:nth-child(even) .content, .field__item.even .fullwidth-background-image .content { margin-left: 0; padding-right: 3%; }

.view__row--even .fullwidth-background-image .content .container--half, .fullwidth-background-image.fullwidth-background-image--paragraph:nth-child(even) .content .container--half, .field__item.even .fullwidth-background-image .content .container--half { margin-left: auto; margin-right: 0; padding: 0; }

@media (max-width: 719px) { .fullwidth-background-image .content .container--half { max-width: 72em; margin: 0 auto; } }

.fullwidth-background-image h1 { text-align: left !important; }

.fullwidth-background-image p { margin-bottom: 0 !important; }

#block--8 { font-weight: bold; }

#block--8 .right { color: black; }

/*------------------------------------*  GRID-SPACING
\*------------------------------------*/
/** CONTENTS BASE................ */
/*------------------------------------*  $BASE
\*------------------------------------*/
.grid-spacing { margin-top: -1em; }

.grid-spacing.grid--condensed { margin-top: -0.875em; }

.grid-spacing__item { margin-top: 1em; }

.grid--condensed .grid-spacing__item { margin-top: 0.875em; }

@media only screen and (min-width: 480px) { .grid-spacing { margin-top: -1em; }
  .grid-spacing__item { margin-top: 1em; } }

@media only screen and (min-width: 720px) { .grid-spacing { margin-top: -1.25em; }
  .grid-spacing__item { margin-top: 1.25em; } }

@media only screen and (min-width: 960px) { .grid-spacing { margin-top: -1.25em; }
  .grid-spacing__item { margin-top: 1.25em; } }

.view--customers { margin-top: 6rem; padding-top: 6rem; border-top: 1px solid #f4f4f4; }

.view--customers .view__header { text-align: center; }

.view--customers .view__row { height: 120px; }

.view--customers .view__row img { -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */ filter: grayscale(100%); }

@media (min-width: 960px) { .view--customers .view__row img { padding: 1em; } }

.paragraphs-item-header-image { text-align: center; background: #f3f3f3; }

.admin-menu #cmp--header { top: 29px; }

.region--navigation { display: inline-block; }

.nav-bar { position: relative; z-index: 10; display: flex; justify-content: center; align-items: center; }

@media (min-width: 720px) and (max-width: 1079px) { .nav-bar { flex-wrap: wrap; }
  .nav-bar .block--main-menu { margin-top: 1em; text-align: center; }
  .nav-bar .menu__list--level1 { display: inline-block; } }

@media (min-width: 1080px) { .nav-bar { flex-wrap: nowrap; justify-content: space-between; } }

.region--navigation { width: 100%; vertical-align: middle; }

.youtube--header { margin-top: 20px; width: 100vw; height: 56.25vw; }

.demo--image { margin-top: 20vh; width: 100vw; height: 56.25vw; }

.cmp--content { padding-top: 2 !important; }

.cmp--highlighted { margin-top: 2rem; }

#block--language ul li { display: inline-block; padding: 0 5px; }

#block--language ul li:first-child { border-right: 1px solid #666; }

.page-contact .cmp--content { margin-top: 2em; }

.field-location-geofield { margin-bottom: 4em; }

.view--locations--page-all { margin-bottom: 3rem; }

.view--locations--page-all .view__row { margin-bottom: 1.25rem; }

.gmap-popup { padding: 1em; }

.gmap-popup .organisation-name { font-size: 1.1em; font-weight: 400; text-transform: uppercase; }

.gmap-popup .field-location-phone { padding-left: 2em; position: relative; margin: 1em 0 0 0; }

.gmap-popup .field-location-phone:before { position: absolute; left: 0; width: 13px; height: 18px; content: url("/sites/all/themes/roromedia/assets/phone.png"); display: inline-block; bottom: 3px; left: 2px; }

.gmap-popup .field-location-email { padding-left: 2em; position: relative; }

.gmap-popup .field-location-email:before { position: absolute; left: 0; width: 18px; height: 14px; content: url("/sites/all/themes/roromedia/assets/email.png"); display: inline-block; }

.field-free-body-field { margin-top: 60px; }

.logo--main { display: inline-block; margin-bottom: 1em; max-width: 80%; }

@media (min-width: 720px) { .logo--main { width: 12em; } }

@media (min-width: 960px) { .logo--main { width: 200px; max-width: none; margin-bottom: 0; margin: 0 1.5em 0 0; } }

@media (max-width: 719px) { .media .media__img { float: none; margin: 0; margin-bottom: 1.25em; } }

.nolink { cursor: pointer; }

@media (min-width: 720px) { .menu--horizontal .menu__link { font-weight: 400; font-family: "Roboto Condensed", sans-serif; }
  .menu--horizontal .menu__link--level1 { text-transform: uppercase; padding: 0.25em 0.75em; transition: color, 0.3s; font-size: 1.2rem; color: black; } }

@media (min-width: 720px) and (max-width: 875px) { .menu--horizontal .menu__link--level1 { padding: 0.25em 0.5em; font-size: 0.85em; } }

@media (min-width: 720px) { .menu--horizontal .menu__link--level1:hover, .menu--horizontal .menu__link--level1:focus { text-decoration: none; color: #25262d; }
  .menu--horizontal .menu__list--level2, .menu--horizontal .menu__list--level3 { z-index: 1003; box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  .menu--horizontal .menu__list--level2 { top: 100%; transition: visibility 0s, opacity 0.3s; font-size: 0.9em; left: 50%; transform: translateX(-50%); min-width: 17.5em; margin-top: 1em; }
  .menu--horizontal .menu__list--level2:after { content: ''; width: 100%; height: 1em; position: absolute; bottom: 100%; left: 0; z-index: 1004; }
  .menu--horizontal .menu__list--level2 .menu__link { color: #000; background: #fff; padding: 1em 0.75em; text-transform: uppercase; display: block; border-bottom: 1px solid #efefef; transition: background, 0.3s; }
  .menu--horizontal .menu__list--level2 .menu__link:hover, .menu--horizontal .menu__list--level2 .menu__link:focus { color: #000; background: #fcf8df; text-decoration: none; }
  .menu--horizontal .menu__list--level2 .menu__link.is-active { font-weight: 700; }
  .menu--horizontal .menu__list--level2 .menu-item:last-child .menu-link { border-bottom: none; }
  .menu--horizontal .menu__list--level3 { min-width: 14em; margin-left: -1px; }
  .region--sidebar1 .block--menu-block h2, .region--sidebar1 .block--menu-block .field--paragraphs_item-custtomer_logos--field-custmer-block-title { font-size: 1.5em; border-bottom: 1px solid #666666; padding-bottom: 0.4em; margin-bottom: 0.8em; }
  .region--sidebar1 .block--menu-block .menu__list .menu__item--level2 { position: relative; margin-top: 0.8em; }
  .region--sidebar1 .block--menu-block .menu__list .menu__item--level2 .menu__list--level3 { display: none; }
  .region--sidebar1 .block--menu-block .menu__list .menu__item--level2.is-open .menu__list--level3 { display: block; }
  .region--sidebar1 .block--menu-block .menu__list .menu__item--level2.is-open .accordion-menu:after { content: "-" !important; }
  .region--sidebar1 .block--menu-block .menu__list .menu__item--level2.is-active .menu__list--level3 { display: block; }
  .region--sidebar1 .block--menu-block .menu__list .menu__item--level2 .menu__link--level2 { font-size: 1.3em; padding-bottom: 0.4em; margin-right: 1em; display: inline-block; }
  .region--sidebar1 .block--menu-block .menu__list .menu__item--level2 .accordion-menu { width: 1.5em; height: 1.5em; position: absolute; right: 0; top: 3px; display: flex; align-items: center; justify-content: center; border: 2px solid #f58220; border-radius: 50%; }
  .region--sidebar1 .block--menu-block .menu__list .menu__item--level2 .accordion-menu:after { content: "+"; color: #f58220; }
  .region--sidebar1 .block--menu-block .menu__list .menu__item--level3 { padding: 0.4em 0 0.4em 2em; border-top: 1px solid #dcdcdc; }
  .region--sidebar1 .block--menu-block .menu__list .menu__item--level3:last-child { border-bottom: 1px solid #dcdcdc; }
  .region--sidebar1 .block--menu-block .menu__list .menu__item--level3 a { color: #666666; }
  .region--sidebar1 .block--menu-block .menu__list .menu__link--level2 { font-weight: 700; } }

@media (max-width: 719px) { .menu__controls { position: absolute; top: 0; right: 10px; }
  .nav-bar { justify-content: flex-start; align-items: flex-start; }
  .menu--horizontal .menu__item { background-color: #000; }
  .menu--horizontal .menu__item .menu__link { padding: 1em; color: #fff; border-bottom: 1px solid #444; transition: all, 0.3s; font-family: "Big Noodle Titling", sans-serif; }
  .menu--horizontal .menu__item .menu__link:focus, .menu--horizontal .menu__item .menu__link:hover { text-decoration: none; background: #333; }
  .menu--horizontal .menu__item .menu__link--level1 { text-transform: uppercase; font-weight: 600; }
  .menu--horizontal .menu__item .menu__link--level2 { text-transform: uppercase; font-size: 0.9em; padding-left: 2.5rem; }
  .menu--horizontal .menu__item .menu__link--level3 { padding-left: 4.5rem; font-size: 0.85em; }
  .menu--horizontal .menu__list--level1 { position: absolute; margin-top: 76px; left: -1em; right: -1em; z-index: 1000; }
  .menu__controls { text-align: right; } }

.burger-menu .cross, .burger-menu .hamburger { line-height: 1em; position: absolute; right: 1rem; font-size: 1.5em; color: #999; z-index: 120; cursor: pointer; top: 69px; }

.burger-menu .cross { display: none; }

.burger-menu .secondary-navigation { overflow-y: scroll; visibility: hidden; right: -15em; position: fixed; top: 0; height: 100vh; z-index: 100; background: #f4f4f4; width: 15em; padding: 1em 0; transition: all 0.3s; }

.burger-menu .secondary-navigation.is-active { visibility: visible; right: 0; }

.burger-menu .secondary-navigation h2, .burger-menu .secondary-navigation .field--paragraphs_item-custtomer_logos--field-custmer-block-title { padding: 0 1.5rem; }

.burger-menu .secondary-navigation .menu__link { padding: 0.75rem 1.5rem; border-bottom: 1px solid #dedede; }

.burger-menu .secondary-navigation .menu__link--level2 { padding-left: 3em; font-size: 0.9em; }

.burger-menu .secondary-navigation .menu__link--level2 .menu__link-level3 { padding-left: 4.5em; padding-left: 1.5em; }

.logo__img { min-width: 160px; }

@media (max-width: 720px) { .menu-name-main-menu { display: none; } }

#block--menu-secondary-menu { position: relative; padding: 6px; float: right; text-align: right; }

#block--menu-secondary-menu ul.menu__list--level1 { display: inline-block; }

#block--menu-secondary-menu ul.menu__list--level1 li { float: left; padding: 0 10px; }

#block--menu-secondary-menu ul.menu__list--level1 li a { color: #666; }

#cmp--offcanvas { background: #f3f3f3; }

#block--language { float: left; padding: 6px; }

#block--language ul.language-switcher-locale-url { margin: 0; padding: 0; }

#block--language ul.language-switcher-locale-url li a { color: #666; }

#block--language ul.language-switcher-locale-url li.active a { color: #f58220; }

#block--main-menu { margin-top: 25px; }

#logo--main { margin-top: 25px; }

a.menu__link--1225 { padding-right: 0 !important; }

/*------------------------------------*  MESSAGE
\*------------------------------------*/
/** CONTENTS BASE................ */
/*------------------------------------*  $BASE
\*------------------------------------*/
.message { margin-bottom: 1em; padding: 1.25em; border-left: 5px solid #3A87AD; background: #e1edf3; color: #3A87AD; }

.message ul { margin: 0; padding-left: 1.5em; }

.message--error { border-color: #b94a48; background: #f5e4e4; color: #b94a48; }

.message--warning { border-color: #c09853; background: #f6f0e5; color: #c09853; }

#sliding-popup .popup-content #popup-text h1, #sliding-popup .popup-content #popup-text h2, #sliding-popup .popup-content #popup-text .field--paragraphs_item-custtomer_logos--field-custmer-block-title, #sliding-popup .popup-content #popup-text h3, #sliding-popup .popup-content #popup-text .request-callback span, .request-callback #sliding-popup .popup-content #popup-text span { font-size: 28px; }

#sliding-popup .popup-content #popup-text p { font-size: 1rem; }

.eu-cookie-compliance-save-preferences-button, .agree-button { box-shadow: none !important; border: 2px solid white !important; border-radius: 0 !important; color: white !important; text-transform: uppercase !important; background-color: transparent !important; text-shadow: none !important; background-image: none !important; }

.cmp--miscellaneous { margin-bottom: 3em; }

@media (min-width: 72em) { .cmp--miscellaneous { margin-top: 3em; } }

.field--node-page--title-field { text-align: center; }

/*------------------------------------*  PAGER
\*------------------------------------*/
/** CONTENTS BASE................ */
/*------------------------------------*  $BASE
\*------------------------------------*/
.pager { text-align: center; }

.pager a { text-decoration: none; }

.pager .pager__item { display: inline-block; margin: 0 0.25em; }

.pager .pager__item--current { font-weight: 700; }

.paragraphs-items h2, .paragraphs-items .field--paragraphs_item-custtomer_logos--field-custmer-block-title { font-family: "Big Noodle Titling", sans-serif; font-weight: 300; text-transform: uppercase; }

.entity-paragraphs-item { margin-top: 2rem; }

.paragraphs-item-intro { font-size: 1.5em; }

.field--paragraphs_item-header_image--field-paragraph-image, .paragraphs-item-intro, .field-paragraph-textarea, .paragraphs-item-50-50 .field--type-text-long, .paragraphs-item-33-33-33 .field--type-text-long, .paragraphs-item-gallery, .paragraphs-item-image-left-text-float, .paragraphs-item-image-with-caption, .paragraphs-item-download { margin-bottom: 4rem; }

.paragraphs-item-50-50 img, .paragraphs-item-33-33-33 img { margin-bottom: 1.5rem; }

.paragraphs-item-image-left-text-float .group-image { float: left; max-width: 40%; margin-right: 1em; margin-bottom: 0.5em; }

@media (max-width: 719px) { .paragraphs-item-image-left-text-float .group-image { max-width: 100%; float: none; text-align: center; }
  .paragraphs-item-image-left-text-float .group-image img { width: 100%; max-width: 550px; display: inline-block; } }

.paragraphs-item-image-left-text-float .field-image-caption { font-size: .9em; font-style: italic; }

.field-paragraph-gallery img { width: 100%; transition: all 0.2s; }

.field-paragraph-gallery img:hover { opacity: 0.75; }

.a-buttons a { font-family: "Big Noodle Titling", sans-serif; font-weight: 300; text-transform: uppercase; display: inline-block; margin: 0 1em 0 0; padding: 0.3em 0.7em 0.4em; white-space: nowrap; cursor: pointer; transition: all, 0.3s; background: transparent; border: 3px solid #f58220; color: #f58220; text-decoration: none; font-size: 1.2em; }

.a-buttons a:hover, .a-buttons a:focus { background: #f58220; color: #fff; border-color: transparent; }

.view--products--page-all img { width: 100%; }

.view--products--page-all .isotope--item { margin-bottom: 1.25rem; }

.view--products--page-all .isotope .grid__inner { background: #f9f9f9; }

.view--products--page-all .view__field--title, .view--products--page-all .view__field-view-node { padding: 0.5rem 1rem; }

.view--products--page-all .view__field--title { padding-bottom: 0; }

.node-type--synex-product #title--main { display: none; }

/*------------------------------------*  ROW-STYLE
\*------------------------------------*/
/** CONTENTS BASE................ */
/*------------------------------------*  $BASE
\*------------------------------------*/
.row-style__item:not(:last-child) { margin-bottom: 2em; padding-bottom: 2em; border-bottom: 1px dotted #666666; }

.row-style--condensed .row-style__item:not(:last-child) { margin-bottom: 1em; padding-bottom: 1em; }

.cmp--secondary-navigation { padding-top: 1.5em; padding-bottom: 2em; }

@media (max-width: 659px) { .cmp--secondary-navigation { padding-top: 0.5em; padding-bottom: 0.5em; } }

.cmp--secondary-navigation .slogan { padding-bottom: 1em; text-align: center; width: 100%; display: none; }

@media (min-width: 720px) { .cmp--secondary-navigation .slogan { display: block; } }

@media (min-width: 960px) { .cmp--secondary-navigation .slogan { padding-bottom: 0; text-align: left; width: auto; } }

.cmp--secondary-navigation .slogan h3, .cmp--secondary-navigation .slogan .request-callback span, .request-callback .cmp--secondary-navigation .slogan span, .cmp--secondary-navigation .slogan h4 { margin: 0; font-size: 1.15em; font-family: "Big Noodle Titling", sans-serif; line-height: 1; }

.cmp--secondary-navigation .slogan h3, .cmp--secondary-navigation .slogan .request-callback span, .request-callback .cmp--secondary-navigation .slogan span { font-weight: 700; }

.cmp--secondary-navigation .top-links { font-family: "Big Noodle Titling", sans-serif; }

.cmp--secondary-navigation .top-links span { margin-left: 0.5em; margin-right: 0.15em; position: relative; font-size: 1.4em; top: 0.15em; }

.cmp--secondary-navigation .top-links svg { max-width: 1.4em; max-height: 1.4em; margin-left: 0.5em; }

@media (max-width: 659px) { .cmp--secondary-navigation .top-links { display: flex; width: 100%; align: center; } }

.cmp--secondary-navigation .top-links .secondary-link { transition: color, 0.3s; display: block; text-align: center; }

@media (max-width: 659px) { .cmp--secondary-navigation .top-links .secondary-link { width: 20%; overflow: hidden; text-transform: none; font-size: 0.8em; }
  .cmp--secondary-navigation .top-links .secondary-link span, .cmp--secondary-navigation .top-links .secondary-link svg { margin: 0; margin-bottom: 0.5em; }
  .cmp--secondary-navigation .top-links .secondary-link span { display: block; }
  .cmp--secondary-navigation .top-links .secondary-link svg { max-width: 1.55em; max-height: 1.55em; }
  .cmp--secondary-navigation .top-links .secondary-link .show-s { display: block; visibility: visible; } }

@media (max-width: 515px) { .cmp--secondary-navigation .top-links .secondary-link { font-size: 0.7em; } }

@media (max-width: 436px) { .cmp--secondary-navigation .top-links .secondary-link { font-size: 0.62em; font-weight: 400; } }

@media (max-width: 366px) { .cmp--secondary-navigation .top-links .secondary-link { font-size: 0.54em; word-break: break-all; } }

@media (min-width: 660px) { .cmp--secondary-navigation .top-links .secondary-link { letter-spacing: 0.1em; display: inline-block; text-align: right; padding-bottom: 0; } }

.cmp--secondary-navigation .top-links .secondary-link:hover, .cmp--secondary-navigation .top-links .secondary-link:focus { text-decoration: none; color: #25262d; }

@media (max-width: 659px) { .cmp--secondary-navigation .container, .cmp--secondary-navigation .page-team #cmp--content, .page-team .cmp--secondary-navigation #cmp--content { padding: 0; } }

/*------------------------------------*  SKIPLINKS
\*------------------------------------*/
/** CONTENTS BASE................ */
/*------------------------------------*  $BASE
\*------------------------------------*/
.skiplinks__list { margin: 0; padding: 0; height: 0; }

.skiplinks__skiplink.u-focusable:active, .skiplinks__skiplink.u-focusable:focus { position: fixed; left: 50%; z-index: 9999; margin-left: -7em; padding: 0.25em 0 0.35em 0; width: 14em; height: auto; outline: 0; background: #000; box-shadow: 0 0 0.75em #666; color: #fff; text-align: center; }

/*------------------------------------*  TABS
\*------------------------------------*/
/** CONTENTS BASE................ */
/*------------------------------------*  $BASE
\*------------------------------------*/
@media (min-width: 480px) { .tabs li { float: left; } }

.tabs a { display: block; padding: 0.5em 0.75em; border-bottom: 1px solid #666666; color: #666666; text-decoration: none; }

.tabs .is-active { border: 1px solid #666666; border-bottom: none; }

.team-member--teaser { position: relative; margin-bottom: 1.25em; transition: background 0.4s; overflow: hidden; }

.team-member--teaser img { transition: opacity, 0.4s; width: 100%; backface-visibility: hidden; }

.team-member--teaser h2, .team-member--teaser .field--paragraphs_item-custtomer_logos--field-custmer-block-title { font-weight: 600; text-transform: uppercase; }

.team-member--teaser h2, .team-member--teaser .field--paragraphs_item-custtomer_logos--field-custmer-block-title, .team-member--teaser h3, .team-member--teaser .request-callback span, .request-callback .team-member--teaser span { color: #fff; transition: color, 0.4s; font-size: 1em; margin-bottom: 0.1em; font-family: "Big Noodle Titling", sans-serif; }

.team-member--teaser .team-member--caption { padding: 20px 30px; background: #f4f4f4; transition: background 0.4s; color: #000; }

.team-member--teaser .team-member--caption a { color: #000; }

.team-member--teaser .field-title-field { display: none; }

.team-member--teaser:after { background-color: #fff; opacity: 0.6; top: 0; bottom: 0; content: ''; left: -100%; position: absolute; width: 200px; box-shadow: 0 0 100px #fff; transform: skew(-20deg); transition: all 0.4s ease; }

.block-link:hover .team-member--teaser img { opacity: 0.7; }

.block-link:hover .team-member--teaser, .block-link:hover .team-member--teaser .team-member--caption { background: #f5f5f5; }

.block-link:hover .team-member--teaser h2, .block-link:hover .team-member--teaser .field--paragraphs_item-custtomer_logos--field-custmer-block-title, .block-link:hover .team-member--teaser h3, .block-link:hover .team-member--teaser .request-callback span, .request-callback .block-link:hover .team-member--teaser span { color: #000; }

.block-link:hover .team-member--teaser:after { left: 200%; }

.team-member--portrait { position: absolute; background: #000; color: #fff; top: 1em; line-height: 1em; z-index: 1; padding: 0.25em 0.75em; font-size: 0.9em; font-weight: 700; font-family: "Big Noodle Titling", sans-serif; }

.team-member--portrait.team-member--portrait--hero { top: auto; font-size: 1.2em; bottom: 8rem; }

@media (max-width: 719px) { .team-member--portrait.team-member--portrait--hero { top: auto; bottom: 0; left: 50%; transform: translateX(-50%) translateY(50%); font-size: 1em; } }

@media (min-width: 960px) { .team-member--portrait.team-member--portrait--hero { font-size: 1.5em; bottom: 11rem; } }

.team--member__title { margin-bottom: 3em; }

.team--member__title h2, .team--member__title .field--paragraphs_item-custtomer_logos--field-custmer-block-title { margin-bottom: 0.1em; }

@media (min-width: 720px) { .team--member__title h2, .team--member__title .field--paragraphs_item-custtomer_logos--field-custmer-block-title { font-size: 3em; } }

.team--member__title h3, .team--member__title .request-callback span, .request-callback .team--member__title span { font-style: italic; font-size: 1.6em; }

.team-member--portrait--number { background: #fcf8df; border-radius: 50%; padding: 0.5em; font-size: 2em; display: inline-block; margin-bottom: 1em; height: 2.5em; width: 2.5em; text-align: center; }

@media (min-width: 720px) { .field-fullwidth-background-text .team-member--portrait--number--wrapper { text-align: inherit; } }

.field-team-member-academic-title { display: inline-block; float: left; font-family: "Big Noodle Titling", sans-serif; font-size: 28px; }

.field-team-member-last-name { font-family: "Big Noodle Titling", sans-serif; font-size: 28px; }

.field-team-member-position { font-weight: bold; font-size: 17px; color: #f58220; }

.field-team-member-email::before { content: "\e75a"; font-family: "themify"; font-size: 0.9em; margin-right: 10px; }

.field-team-member-phone { margin-top: 20px; }

.field-team-member-phone::before { content: "\f095"; font-family: "FontAwesome"; font-size: 1em; margin-right: 10px; }

.cmp--teaser { position: relative; /*background: $cs-grey-lighter;*/ background: rgba(244, 244, 244, 0.9); padding: 3em; margin-bottom: 1rem; }

.cmp--content .cmp--teaser .field-teaser-headline { margin-bottom: 4em; }

.cmp--teaser h3, .cmp--teaser .request-callback span, .request-callback .cmp--teaser span { color: #000; margin-bottom: 1em; }

.cmp--teaser .cmp--teaser-icon { height: 3em; }

.cmp--teaser .cmp--teaser-icon img { width: 2em; }

.cmp--teaser .cmp--teaser-plus-icon { position: absolute; bottom: 3em; }

.cmp--teaser .cmp--teaser-plus-icon svg { width: 3em; height: 3em; }

.cmp--teaser .cmp--teaser-plus-icon svg path { transition: all 0.5s ease; }

.cmp--teaser .cmp--teaser-plus-icon svg:hover path.outer { fill: #f58220 !important; }

.cmp--teaser .cmp--teaser-plus-icon svg:hover path.inner { stroke: #fff !important; }

.cmp--teaser .block--nodeblock h2, .cmp--teaser .block--nodeblock .field--paragraphs_item-custtomer_logos--field-custmer-block-title { font-size: 2em; }

@media (min-width: 720px) { .cmp--teaser .block--nodeblock h2, .cmp--teaser .block--nodeblock .field--paragraphs_item-custtomer_logos--field-custmer-block-title { font-size: 3em; } }

blockquote { border-left: 0.5rem solid #666666; margin-left: 0; padding-left: 1.5rem; font-size: 0.8em; }

blockquote em { font-size: 1.1rem; margin-bottom: 0.5rem; display: inline-block; }

@media (min-width: 720px) { blockquote em { font-size: 1.2rem; } }

.block--teasers-block-front { margin-top: 4em; }

.block--teasers-block-front > .view { padding: 1.25em; background: #fff; }

.block--teasers-block-front img { width: 100%; }

.block--teasers-block-front .field-teaser-headline, .block--teasers-block-front .button, .block--teasers-block-front .flex-caption a, .flex-caption .block--teasers-block-front a, .block--teasers-block-front .teaser-unesco-logo { position: absolute; }

.block--teasers-block-front .teaser-unesco-logo { bottom: 2em; right: 2em; width: 3em; }

.block--teasers-block-front .button, .block--teasers-block-front .flex-caption a, .flex-caption .block--teasers-block-front a { pointer-events: none; }

.block--teasers-block-front .teaser:hover .button, .block--teasers-block-front .teaser:hover .flex-caption a, .flex-caption .block--teasers-block-front .teaser:hover a { background: #fff; color: #000; }

.block--teasers-block-front .teaser--first { margin-bottom: 1.25em; }

.block--teasers-block-front .teaser--first .button, .block--teasers-block-front .teaser--first .flex-caption a, .flex-caption .block--teasers-block-front .teaser--first a { bottom: 2em; left: 2em; }

@media (min-width: 960px) { .block--teasers-block-front .teaser--first { margin-bottom: 0; } }

@media (max-width: 479px) { .block--teasers-block-front .teaser--first .button, .block--teasers-block-front .teaser--first .flex-caption a, .flex-caption .block--teasers-block-front .teaser--first a { font-size: 0.8em; bottom: 1em; left: 1em; right: 1em; padding: 0.5em 0.2em; text-align: center; } }

.block--teasers-block-front .teaser--other { margin-bottom: 1.25em; }

.block--teasers-block-front .teaser--other .button, .block--teasers-block-front .teaser--other .flex-caption a, .flex-caption .block--teasers-block-front .teaser--other a { bottom: 1em; left: 1em; right: 1em; font-size: 0.75em; text-align: center; }

@media (min-width: 480px) { .block--teasers-block-front .teaser--other:nth-last-child(-n+2) { margin-bottom: 0; } }

@media (max-width: 479px) { .block--teasers-block-front .teaser--other:last-child { margin-bottom: 0; }
  .block--teasers-block-front .teaser--other .button, .block--teasers-block-front .teaser--other .flex-caption a, .flex-caption .block--teasers-block-front .teaser--other a { font-size: 0.75em; } }

@media (max-width: 959px) { .block--teasers-block-front .teaser--other .button, .block--teasers-block-front .teaser--other .flex-caption a, .flex-caption .block--teasers-block-front .teaser--other a { font-size: 1em; } }

@media (max-width: 719px) { .block--teasers-block-front .teaser--other .button, .block--teasers-block-front .teaser--other .flex-caption a, .flex-caption .block--teasers-block-front .teaser--other a { font-size: 0.8em; padding: 0.5em 0.2em; } }

@media (min-width: 1100px) { .block--teasers-block-front .teaser--other .button, .block--teasers-block-front .teaser--other .flex-caption a, .flex-caption .block--teasers-block-front .teaser--other a { font-size: 0.9em; } }

@media (min-width: 1200px) { .block--teasers-block-front .teaser--other .button, .block--teasers-block-front .teaser--other .flex-caption a, .flex-caption .block--teasers-block-front .teaser--other a { font-size: 1em; } }

.block--teasers-block-front .field-teaser-headline { color: #fff; left: 2rem; right: 2rem; bottom: 6rem; font-size: 1.2em; pointer-events: none; }

@media (min-width: 720px) { .block--teasers-block-front .field-teaser-headline { font-size: 1.5em; } }

.view--teasers--teaser-front__header { margin-bottom: 3em; }

.player { position: relative; padding-bottom: 56.25%; padding-top: 0; height: 0; overflow: hidden; }

.player iframe { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

.septem-menu { z-index: 101; }

.front .cmp--content h1 { text-align: center; margin: 0 auto; max-width: 13em; margin-bottom: 0.75em; }

.cmp--content { margin-top: 2em; }

.form__item--field-form-contact-datenschutz-und { display: flex !important; }

.form__item--field-form-contact-datenschutz-und > label { display: none !important; }

.form__item--field-form-contact-datenschutz-und > small { margin-left: 10px !important; font-size: 16px; }

.form__item--field-form-contact-datenschutz-und > input { margin-top: 3px !important; }

.front .cmp--content h1.field--node-page--title-field { display: none !important; }

.field-folder { display: none; }

@media only screen and (min-width: 600px) { .text-left-box, .text-right-box { width: 50%; float: left; } }

/* main#content--main { box-shadow: inset 0px 0px 30px rgba(0,0,0,0.2); padding-top: 30px; } body.front { main#content--main { box-shadow: none; padding-top: 0; } } */
body { -webkit-font-smoothing: antialiased; }

#block--contact { padding: 0; }

#cmp--content { box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.3); padding-top: 5em; padding-bottom: 4em; }

body.page-contact #cmp--content { margin-top: 0; padding-bottom: 0; }

body.front #cmp--content { margin-top: -30px; padding-bottom: 0; }

.view--news--page-all__header { margin-bottom: 100px; }

h1#title--main { margin-bottom: 2em; }

h1.field-title-field { margin-bottom: 2em; }

.url-textfield { visibility: hidden; height: 10px; }

.organisation-name { font-size: 2em; }

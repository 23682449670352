#block--callback-service {
  padding: 40px;
  box-shadow: 2px 2px 20px 0px #efefef;
  min-height: 480px;
  margin-bottom: 100px;
  #edit-field-form-contact-quelle {
    display: none !important;
  }
  #callback-service-entityform-edit-form > div {
    // display: flex;
    // flex-wrap: wrap;
    position: relative;
    #edit-field-form-contact-name,
    #edit-field-form-contact-email,
    #edit-field-form-contact-telefon {
      width: 48%;
      position: absolute;
      left: 0;
    }

    #edit-field-form-contact-message {
      position: absolute;
      width: 48%;
      right: 0;
    }

    #edit-field-form-contact-email {
      top: 80px;
    }

    #edit-field-form-contact-telefon {
      top: 160px;
    }
    textarea {
      min-height: 197px;
    }
		.captcha {
			position: absolute;
			top: 260px;
			left: 130px;
		}
    #edit-actions {
      position: absolute;
      top: 240px;
    }
    @media(max-width: 550px) {
      div {
        width: 100% !important;
        position: relative !important;
        top: initial !important;
      }
    }
  }
}

.page-node--312 {
  h1 {
    position: relative;
    font-size: 5em;
    @media (min-width: $breakpoint-m) {
      font-size: 12em;
    }
    margin-bottom: 0;
    animation: pulse 15s;
  }
  .ti-unlink {
    position: absolute;
    top: 0;
    display: block;
    font-size: 4rem;
        @media (min-width: $breakpoint-m) {
          position: absolute;
display: inline-block;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

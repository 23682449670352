.filter {
  list-style: none;
  padding: 0;
  border-bottom: 0.1em solid #e9e9e9
}

.filter__item {
  display: inline-block;
  font-size: 1.2rem;
}

.filter__filter {
  padding: 0.5rem 1.25rem;
  display: block;
  border-bottom: 0.25rem solid transparent;
  &:hover,
  &:focus {
    text-decoration: none;
  }
  &.active {
    border-bottom-color: $cs-b;
  }
}

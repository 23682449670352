/*------------------------------------*\
  TYPOGRAPHY
\*------------------------------------*/


/*------------------------------------*\
  $WHITESPACE
\*------------------------------------*/
/**
 * Add whitespace around some block elements
 */
p,
ul,
ol,
dl,
pre,
form,
table,
figure,
address,
fieldset,
blockquote {
  margin-top: 0;
  margin-bottom: (($font-base-line-height / $font-base-size) * 1em);
}

ul,
ol,
dl {
  padding-left: 1.5em;
}

/**
 * no margin for nested lists
 */
li {
  ul,
  ol,
  dl {
    margin-bottom: 0;
  }
}

/**
 * no margin for nested tables
 */
table {
  table {
    margin-bottom: 0;
  }
}

/**
 * Normalize default font related tags
 */
b,
strong {
  font-weight: $font-a-weight-bold;
}

small {
  font-size: $font-size-s;
}
select,
input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  border: 1px solid #cdcdce;
  width: 100%;
  background: transparent;
  margin-bottom: 0.5em;
  font-family: $font-b-family;
}

input:focus,
input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
  box-shadow: none;
}

.form__label {
  font-family: $font-a-family;
  font-weight: 300;
  font-size: 1.5em;
  color: black;
  letter-spacing: 0.5px;
}

.block--contact {
  input[type="text"],
  input[type="email"],
  input[type="password"],
  textarea {
    background: rgba(#fff, 0.5);
  }
}

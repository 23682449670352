.cmp--teaser {
  position: relative;
  /*background: $cs-grey-lighter;*/
  background: rgba(244, 244, 244, 0.9);
  padding: 3em;
  margin-bottom: 1rem;
  .cmp--content & {
    .field-teaser-headline {
      margin-bottom: 4em;
    }
  }
  h3 {
    color: $cs-a;
    margin-bottom: 1em;
  }
  .cmp--teaser-icon {
    height: 3em;
    img {
      width: 2em;
    }
  }
  .cmp--teaser-plus-icon {
    position: absolute;
    bottom: 3em;
    svg {
      width: 3em;
      height: 3em;
      path {
        transition: all 0.5s ease;
      }
      &:hover {
        path.outer {
          fill: $cs-b !important;
        }
        path.inner {
          stroke: #fff !important;
        }
      }
    }
  }
  .block--nodeblock {
    h2 {
      font-size: 2em;
      @media (min-width: $breakpoint-m) {
        font-size: 3em;
      }
    }
  }
}

blockquote {
  border-left: 0.5rem solid $font-base-color;
  margin-left: 0;
  padding-left: 1.5rem;
  font-size: 0.8em;
  em {
    font-size: 1.1rem;
    @media (min-width: $breakpoint-m) {
      font-size: 1.2rem;
    }
    margin-bottom: 0.5rem;
    display: inline-block;
  }
}

.block--teasers-block-front {
  margin-top: 4em;
  >.view {
    padding: 1.25em;
    background: #fff;
  }
  img {
    width: 100%;
  }
  .field-teaser-headline,
  .button,
  .teaser-unesco-logo {
    position: absolute;
  }
  .teaser-unesco-logo {
    bottom: 2em;
    right: 2em;
    width: 3em;
  }
  .button {
    pointer-events: none;
  }
  .teaser:hover .button {
    background: #fff;
    color: #000;
  }
  .teaser--first {
    margin-bottom: 1.25em;
    .button {
      bottom: 2em;
      left: 2em;
    }
    @media (min-width: $breakpoint-l) {
      margin-bottom: 0;
    }
    @media (max-width: $breakpoint-s-max) {
      .button {
        font-size: 0.8em;
        bottom: 1em;
        left: 1em;
        right: 1em;
        padding: 0.5em 0.2em;
        text-align: center;
      }
    }
  }
  .teaser--other {
    margin-bottom: 1.25em;
    .button {
      bottom: 1em;
      left: 1em;
      right: 1em;
      font-size: 0.75em;
      text-align: center;
    }
    @media (min-width: $breakpoint-s) {
      &:nth-last-child(-n+2) {
        margin-bottom: 0;
      }
    }
    @media (max-width: $breakpoint-s-max) {
      &:last-child {
        margin-bottom: 0;
      }
      .button {
        font-size: 0.75em;
      }
    }
    @media (max-width: $breakpoint-l-max) {
      .button {
        font-size: 1em;
      }
    }
    @media (max-width: $breakpoint-m-max) {
      .button {
        font-size: 0.8em;
        padding: 0.5em 0.2em;
      }
    }
    @media (min-width: 1100px) {
      .button {
        font-size: 0.9em;
      }
    }
    @media (min-width: $breakpoint-xl) {
      .button {
        font-size: 1em;
      }
    }
  }
  .field-teaser-headline {
    color: #fff;
    left: 2rem;
    right: 2rem;
    bottom: 6rem;
    font-size: 1.2em;
    pointer-events: none;
    @media (min-width: $breakpoint-m) {
      font-size: 1.5em;
    }
  }
}

.view--teasers--teaser-front__header {
  margin-bottom: 3em;
}

.cmp--secondary-navigation {
  padding-top: 1.5em;
  padding-bottom: 2em;
  @media (max-width: 659px) {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  .slogan {
    padding-bottom: 1em;
    text-align: center;
    width: 100%;
    display: none;
    @media (min-width: $breakpoint-m) {
      display: block;
    }
    @media (min-width: $breakpoint-l) {
      padding-bottom: 0;
      text-align: left;
      width: auto;
    }
    h3,
    h4 {
      margin: 0;
      font-size: 1.15em;
      font-family: $font-a-family;
      line-height: 1;
    }
    h3 {
      font-weight: 700;
    }
  }
  .top-links {
    font-family: $font-a-family;
    span {
      margin-left: 0.5em;
      margin-right: 0.15em;
      position: relative;
      font-size: 1.4em;
      top: 0.15em;
    }
    svg {
      max-width: 1.4em;
      max-height: 1.4em;
      margin-left: 0.5em;
    }
    @media (max-width: 659px) {
      display: flex;
      width: 100%;
      align: center;
    }
    .secondary-link {
      transition: color, 0.3s;
      display: block;
      text-align: center;
      @media (max-width: 659px) {
        width: 20%;
        overflow: hidden;
        text-transform: none;
        font-size: 0.8em;
        span,
        svg {
          margin: 0;
          margin-bottom: 0.5em;
        }
        span {
          display: block;
        }
        svg {
          max-width: 1.55em;
          max-height: 1.55em;
        }
        .show-s {
          display: block;
          visibility: visible;
        }
      }
      @media (max-width: 515px) {
        font-size: 0.7em;
      }
      @media (max-width: 436px) {
        font-size: 0.62em;
        font-weight: 400;
      }
      @media (max-width: 366px) {
        font-size: 0.54em;
        word-break: break-all;
      }
      @media (min-width: 660px) {
        letter-spacing: 0.1em;
        display: inline-block;
        text-align: right;
        padding-bottom: 0;
      }
      &:hover,
      &:focus {
        text-decoration: none;
        color: $cs-grey-dark;
      }
    }
  }
}

.region--secondary-navigation {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-wrap: wrap;
  // @media (min-width: $breakpoint-l) {
  //   justify-content: space-between;
  // }
}

@media (max-width: 659px) {
  .cmp--secondary-navigation .container {
    padding: 0;
  }
}

.paragraphs-item-custtomer-logos {
  .field-customer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  article {
    width: 20%;
    float: left;
    height: 150px;
    display: flex;
    justify-content: center;
    header h2 {
      display: none;
    }
    h3 {
      display: none;
    }
    .form__item {
      display: none;
    }
    .field-customer-show-on-frontpage {
      display: none;
    }
    .field-customer-logo  {
      text-align: center;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      height: 100%;
      -ms-flex-pack: center;
      justify-content: center;
      img  {
        filter: grayscale(100%);
      }
    }
  }
}


@media only screen and (max-width: 992px) {
  .paragraphs-item-custtomer-logos {
    article {
      width: 50%;
      float: left;
    }
  }
}

@media only screen and (max-width: 500px) {
  .paragraphs-item-custtomer-logos {
    article {
      width: 100%;
      float: left;
    }
  }
}

.field--paragraphs_item-custtomer_logos--field-custmer-block-title {
  @extend h2;
  text-align: center;
  margin-bottom: 1em;
}

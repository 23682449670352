.container {

}

.container--white {
  background: #fff;
}

.container--no-spacing {
  padding: 0;
}

//.container--s {
  //max-width: 46rem;
//}

.container--l {
  max-width: 102rem;
}

.container--half {
  max-width: 28rem;
  margin-left: 0;
}

.region--sidebar1 {
  margin-top: 5rem;
}

.links.inline {
  .translation_en {
    display: none;
  }
}

/**
 * Colorbox Core Style:
 * The following CSS is consistent between example themes and should not be altered.
 */

#colorbox,
#cboxOverlay,
#cboxWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
}

#cboxOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
}

#cboxMiddleLeft,
#cboxBottomLeft {
  clear: left;
}

#cboxContent {
  position: relative;
}

#cboxLoadedContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

#cboxTitle {
  margin: 0;
}

#cboxLoadingOverlay,
#cboxLoadingGraphic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


/**
 * These elements are buttons, and may need to have additional
 * styles reset to avoid unwanted base styles.
 */

#cboxPrevious,
#cboxNext,
#cboxClose,
#cboxSlideshow {
  border: 0;
  padding: 0;
  margin: 0;
  overflow: visible;
  width: auto;
  background: none;
  cursor: pointer;
}

#cboxPrevious,
#cboxNext,
#cboxClose {
  position: absolute;
  font-family: 'themify';
  speak: none;
  color: #fff;
  &:after {
    display: block;
    font-size: 2em;
  }
}

#cboxClose {
  top: -3em;
  right: 0;
  &:after {
    content: "\e646";
  }
}

#cboxNext {
  right: 0;
  padding-top: 0.25rem;
  &:after {
    content: "\e649";
  }
}

#cboxPrevious {
  padding-top: 0.25rem;
  &:after {
    content: "\e64a";
  }
}

#cboxCurrent {
  display: none !important;
}


/**
 * Avoid outlines on :active (mouseclick),
 * but preserve outlines on :focus (tabbed navigating)
 */

#cboxPrevious:active,
#cboxNext:active,
#cboxClose:active,
#cboxSlideshow:active {
  outline: 0;
}

.cboxPhoto {
  float: left;
  margin: auto;
  border: 0;
  display: block;
  max-width: none;
}

.cboxIframe {
  width: 100%;
  height: 100%;
  display: block;
  border: 0;
}

#colorbox,
#cboxContent,
#cboxLoadedContent {
  box-sizing: content-box;
}

#cboxOverlay {
  background: #000;
}

#colorbox {
  outline: 0;
}

#cboxLoadingOverlay {
  background: #fff;
}

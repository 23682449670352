/*------------------------------------*\
  FORM
\*------------------------------------*/
/**
 * CONTENTS
 * BASE................
 * DATE................
 */



/*------------------------------------*\
  $BASE
\*------------------------------------*/

.form__item {
  margin-bottom: $spacing-xs;
  .form__item {
    margin-bottom: 0;
  }
}

.form__wrapper {
  margin-bottom: 0;
}

.form__label,
.form__fieldset-legend {
  font-weight: $font-a-weight-medium;
}



/*------------------------------------*\
  $DATE
\*------------------------------------*/

.form__item--date-select {
  .form__item {
    display: inline-block;
    margin-right: $spacing-xs;
    margin-bottom: 0;
  }
  .form__label {
    font-weight: $font-a-weight-normal;
  }
}
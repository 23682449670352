@if $cmp-message {

/*------------------------------------*\
  MESSAGE
\*------------------------------------*/
/**
 * CONTENTS
 * BASE................
 */



/*------------------------------------*\
  $BASE
\*------------------------------------*/

.message {
  margin-bottom: $spacing-s;
  padding: $spacing-m;
  border-left: 5px solid $cs-neutral;
  background: mix($cs-neutral, #fff, 15%);
  color: $cs-neutral;
  ul {
    margin: 0;
    padding-left: 1.5em;
  }
}

.message--error {
  border-color: $cs-negative;
  background: mix($cs-negative, #fff, 15%);
  color: $cs-negative;
}

.message--warning {
  border-color: $cs-cautious;
  background: mix($cs-cautious, #fff, 15%);
  color: $cs-cautious;
}

} // endif


#sliding-popup .popup-content #popup-text h1, 
#sliding-popup .popup-content #popup-text h2, 
#sliding-popup .popup-content #popup-text h3 {
  font-size: 28px;
}

#sliding-popup .popup-content #popup-text p {
  font-size: 1rem;
}

.eu-cookie-compliance-save-preferences-button, .agree-button {
  box-shadow: none !important;
  border: 2px solid white !important;
  border-radius: 0 !important;
  color: white !important;
  text-transform: uppercase !important;
  background-color: transparent !important;
  text-shadow: none !important;
  background-image: none !important;
}
.logo--main {
  display: inline-block;
  margin-bottom: 1em;
  max-width: 80%;

  @media (min-width: $breakpoint-m) {
    width: 12em;
  }

  @media (min-width: $breakpoint-l) {
    width: 200px;
    max-width: none;
    margin-bottom: 0;
    margin: 0 1.5em 0 0;
  }
}

